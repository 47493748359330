import toast from "react-hot-toast";
import { supabase } from ".";

export const getFacturasComision2 = async ({ edificio, fechas }) => {
  try {
    const data = await supabase.select("sales", {
      filters: {
        facturafecha: { gte: fechas[0], lte: fechas[1] },
        edificiosparticipantes: { like: edificio },
        eliminada: !true,
      },
    });

    if (data?.length === 0) {
      toast.error("No hay facturas asociadas al edificio en las fechas seleccionadas");
      throw new Error("No data found");
    }

    return data;
  } catch {
    return [];
  }
};
