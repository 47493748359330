import { supabase } from ".";

export const saveIntermediario = async (intermediario) => {
  try {
    const data = await supabase.insert("intermediarios", intermediario);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveIntermediario", error);
    throw error;
  }
};
