import { Navigate } from "react-router-dom";
import { useAuthContext } from "../../context/auth";
import { Loader } from "../Loader";

const PrivateRoute = ({ children, allowedRoles }) => {
  const { session, rol } = useAuthContext();

  if (session === undefined) {
    return <Loader fullPage />;
  }

  if (!session || !allowedRoles.includes(rol)) {
    return <Navigate to="/login" />;
  }

  return children;
};

export default PrivateRoute;
