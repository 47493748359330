import { BlackArrow, Container, VentasDetalle } from "../../components";
import "./styles.scss";

const VentasTabla = () => (
  <>
    <BlackArrow />
    <Container header titulo="PROYECCIÓN COMERCIAL">
      <VentasDetalle />
    </Container>
  </>
);

export default VentasTabla;
