/* eslint-disable no-console */
import React, { useState, useEffect } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { Container, ListaEdificios } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificios, saveUsuario } from "../../utils";
import { getUsers } from "../../utils/getUsers";

import "./styles.scss";

export const Usuarios = () => {
  const [edificios, setEdificios] = useState([]);
  const methods = useForm(); // Inicializa el form context
  const [usuarios, setUsuarios] = useState([]);
  const [sortColumn, setSortColumn] = useState("nombre");
  const [sortDirection, setSortDirection] = useState("asc");
  const [newUser, setNewUser] = useState({
    nombre: "",
    apellido: "",
    email: "",
    rol: "", // Inicializado como cadena vacía
    edificiosOk: "",
  });
  const [message, setMessage] = useState("");
  const [showListaEdificios, setShowListaEdificios] = useState(false);

  const { pais } = useAuthContext();

  useEffect(() => {
    const fetchEdificios = async () => {
      try {
        const data = await getEdificios({ pais });
        setEdificios(data);
      } catch (error) {
        console.error("Error al obtener edificios:", error);
      }
    };

    fetchEdificios();
  }, [pais]);

  const fetchUsuarios = async () => {
    try {
      const newUsuarios = await getUsers();
      setUsuarios(newUsuarios);
    } catch (error) {
      console.error("Error al obtener usuarios:", error);
    }
  };

  useEffect(() => {
    fetchUsuarios();
  }, []);

  // useEffect(() => {
  //   // Asigna automáticamente el rol según el rol del usuario logueado si no es superadmin
  //   if (rol === "comercial") {
  //     setNewUser((prevUser) => ({ ...prevUser, rol: "agencia" }));
  //     setShowListaEdificios(false);
  //   } else if (rol === "administrativo") {
  //     setNewUser((prevUser) => ({ ...prevUser, rol: "edificio" }));
  //     setShowListaEdificios(true);
  //   }
  // }, [rol]);

  const getNombresEdificios = (edificiosOk) => {
    // Si edificiosOk es una cadena, conviértela en un array de números
    if (typeof edificiosOk === "string") {
      edificiosOk = edificiosOk.split(",").map((id) => parseInt(id.trim(), 10));
    }

    if (!edificiosOk || !Array.isArray(edificiosOk)) return "No Aplica";

    const nombres = edificios
      .filter((edificio) => edificiosOk.includes(edificio.id))
      .map((edificio) => edificio.edificio)
      .join(", ");

    return nombres || "No Aplica";
  };

  const handleSort = (column) => {
    const newSortDirection = sortColumn === column && sortDirection === "asc" ? "desc" : "asc";
    setSortColumn(column);
    setSortDirection(newSortDirection);
  };

  const sortedUsuarios = usuarios
    .filter((usuario) => {
      // Filtrar por país y excluir ciertos roles
      const validPais =
        (pais === "Uruguay" && usuario.pais === "URU") ||
        (pais === "Perú" && usuario.pais === "PER") ||
        (pais === "Chile" && usuario.pais === "CHI");

      const excludedRoles = ["superadmin", "comercial", "administrativo"];
      const validRole = !excludedRoles.includes(usuario.rol);

      return validPais && validRole;
    })
    .sort((a, b) => {
      if (a[sortColumn] < b[sortColumn]) return sortDirection === "asc" ? -1 : 1;
      if (a[sortColumn] > b[sortColumn]) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Actualiza el nuevo usuario con el valor del input
    setNewUser((prevUser) => ({
      ...prevUser,
      [name]: value,
    }));

    // Si el campo cambiado es 'rol', actualiza showListaEdificios en consecuencia
    if (name === "rol") {
      // Muestra la lista de edificios solo si el rol seleccionado es "edificio"
      setShowListaEdificios(value === "edificio");

      // Si el rol es diferente de "edificio", limpia los edificios seleccionados
      if (value !== "edificio") {
        setNewUser((prevUser) => ({
          ...prevUser,
          edificiosOk: "",
        }));
      }
    }
  };

  const handleAddUser = async (e) => {
    e.preventDefault();

    // Obtener edificios seleccionados desde el form context
    const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");

    const userWithCountry = {
      ...newUser,
      pais:
        {
          Uruguay: "URU",
          Perú: "PER",
          Chile: "CHI",
        }[pais] || "",
      edificiosOk: edificiosSeleccionados,
    };

    try {
      const data = await saveUsuario(userWithCountry);

      if (Array.isArray(data)) {
        setUsuarios([...usuarios, ...data]);
      } else if (data) {
        setUsuarios([...usuarios, data]);
      }

      setNewUser({
        nombre: "",
        apellido: "",
        email: "",
        rol: "",
        edificiosOk: "",
      });

      setMessage("Usuario guardado correctamente, actualizando lista usuarios");
      setTimeout(() => {
        setMessage("");
        window.location.reload(); // Refresca toda la página
      }, 3000);
    } catch (error) {
      console.error("Error al agregar usuario:", error);
    }
  };

  return (
    <Container className="Usuarios" header titulo="Usuarios">
      {message && <div className="alert alert-success">{message}</div>}
      <FormProvider {...methods}>
        <form onSubmit={handleAddUser} className="row justify-content-center align-items-center mb-4">
          <div className="col-2">
            <input
              type="text"
              className="form-control mb-2"
              id="nombre"
              name="nombre"
              placeholder="Nombre"
              value={newUser.nombre}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-2">
            <input
              type="text"
              className="form-control mb-2"
              id="apellido"
              name="apellido"
              placeholder="Apellido"
              value={newUser.apellido}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-2">
            <input
              type="email"
              className="form-control mb-2"
              id="email"
              name="email"
              placeholder="Correo"
              value={newUser.email}
              onChange={handleInputChange}
              required
            />
          </div>
          <div className="col-2">
            <select
              className="form-control mb-2"
              id="rol"
              name="rol"
              value={newUser.rol}
              onChange={handleInputChange}
              required
            >
              <option value="" disabled>
                Seleccionar
              </option>
              <option value="agencia">Agencia</option>
              <option value="edificio">Edificio</option>
            </select>
          </div>
          <input type="hidden" name="rol" value={newUser.rol} />
          <div className="col-auto">
            <button type="submit" className="btn btn-primary mb-2">
              Agregar Usuario
            </button>
          </div>
        </form>
        {showListaEdificios && (
          <div className="mb-5">
            {/* Aquí se aplica el margin-bottom */}
            <ListaEdificios
              forceSubmit={() => {
                const edificiosSeleccionados = methods.getValues("edificiosSeleccionados");
                setNewUser((prevState) => ({
                  ...prevState,
                  edificiosOk: edificiosSeleccionados,
                }));
              }}
            />
          </div>
        )}
      </FormProvider>
      <table className="table table-fixed table-bordered table-hover align-middle">
        <thead className="table-dark text-center align-middle">
          <tr>
            <th width="10%" onClick={() => handleSort("nombre")} style={{ cursor: "pointer" }}>
              Nombre {sortColumn === "nombre" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="10%" onClick={() => handleSort("apellido")} style={{ cursor: "pointer" }}>
              Apellido {sortColumn === "apellido" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="25%" onClick={() => handleSort("email")} style={{ cursor: "pointer" }}>
              Correo {sortColumn === "email" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="10%" onClick={() => handleSort("rol")} style={{ cursor: "pointer" }}>
              Rol {sortColumn === "rol" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
            <th width="30%" onClick={() => handleSort("rol")} style={{ cursor: "pointer" }}>
              Edificios OK {sortColumn === "edificiosOk" && (sortDirection === "asc" ? "↑" : "↓")}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedUsuarios.map((usuario) => (
            <tr key={usuario.id}>
              <td className="text-start">{usuario.nombre}</td>
              <td className="text-start">{usuario.apellido}</td>
              <td>{usuario.email.toLowerCase()}</td>
              <td>{usuario.rol}</td>
              <td>{usuario.rol === "agencia" ? "No Aplica" : getNombresEdificios(usuario.edificiosOk)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </Container>
  );
};
