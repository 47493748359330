/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { Loader } from "..";
import { FaMobileScreen } from "react-icons/fa6";
import { FaLocationDot } from "react-icons/fa6";
import { HiOutlineBuildingOffice } from "react-icons/hi2";
import { IoPeople } from "react-icons/io5";
import { MdElevator } from "react-icons/md";
import { PiClockUserFill } from "react-icons/pi";
import { TiStar } from "react-icons/ti";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./stylesFichaEdificios.scss";

export const FichaEdificios = () => {
  const [edificios, setEdificios] = useState([]);
  const [imageSrc, setImageSrc] = useState({});

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  useEffect(() => {
    const initialize = async () => {
      // Obtener los edificios operativos
      const newEdificios = await getEdificiosOperativos({ pais });

      // Ordenar los edificios en función de la cantidad de personas y luego por la suma de pantallas, totems y espera
      newEdificios.sort((a, b) => {
        // Comparar primero por la cantidad de personas
        if (b.personas !== a.personas) {
          return b.personas - a.personas;
        }

        // Si las cantidades de personas son iguales, comparar por la suma total de pantallas, totems y espera
        const sumaA = (a.pantallas || 0) + (a.totems || 0) + (a.cowork || 0) + (a.espera || 0);
        const sumaB = (b.pantallas || 0) + (b.totems || 0) + (b.cowork || 0) + (b.espera || 0);
        return sumaB - sumaA;
      });

      // Establecer los edificios ordenados en el estado
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    if (edificios) {
      edificios.forEach(async (edificio) => {
        try {
          const src = await import(`../../img/edificios/${edificio.id}.png`);
          setImageSrc((prevState) => ({ ...prevState, [edificio.id]: src.default }));
        } catch {
          setImageSrc((prevState) => ({ ...prevState, [edificio.id]: null }));
        }
      });
    }
  }, [edificios]);

  const renderEmpresasInfo = (empresaslistado) => {
    if (!empresaslistado || empresaslistado.toUpperCase() === "NO DISPONIBLE") {
      return "NO DISPONIBLE";
    }
    const cantidad = empresaslistado.split(",").length;
    return `${cantidad} EMPRESAS`;
  };

  if (edificios === undefined) return <Loader />;

  if (edificios.length === 0) {
    return <div>No hay edificios disponibles.</div>;
  }

  return (
    <div className="body-ficha">
      {edificios.map((edificio) => (
        <div key={edificio.id} className="ficha-edificios">
          {imageSrc[edificio.id] && (
            <img className="edificios-imagen-edificio" src={imageSrc[edificio.id]} alt={edificio.edificio} />
          )}
          <div className="nombre-edificio"> {edificio.edificio || ""} </div>
          <div className="div-ubicacion">
            <FaLocationDot className="ubicacion-icono" />
            <div className="ubicacion-texto">
              {edificio.direccion.toUpperCase()}, {edificio.ubicacion}
            </div>
            {/* <div className="ubicacion-bajada">{edificio.ubicacion}</div> */}
          </div>
          <div className="div-ascensores">
            <MdElevator className="ascensores-icono" />
            <div className="ascensores-texto">
              {edificio.pantallas ? `${edificio.pantallas} ASCENSORES con pantallas FULLHD` : "-"}
            </div>
          </div>
          <div className="div-totems">
            <FaMobileScreen className="totems-icono" />
            <div className="totems-texto">
              {edificio.totems || edificio.cowork
                ? `${edificio.totems || edificio.cowork} ${edificio.totems || edificio.cowork > 1 ? "TÓTEMS de circulación" : "TÓTEM de circulación"}`
                : "-"}
            </div>
          </div>
          <div className="div-espera">
            <PiClockUserFill className="espera-icono" />
            <div className="espera-texto">
              {edificio.espera
                ? `${edificio.espera} ${edificio.espera > 1 ? "PANTALLAS en hall de espera" : "PANTALLA en hall de espera"}`
                : "-"}
            </div>
          </div>
          <div className="contenedor-blanco">
            <div className="div-people">
              <IoPeople className="people-icono" />
              <div className="people-numero">{edificio.personas.toLocaleString(localString(pais))}</div>
              <div className="people-texto">PERSONAS ÚNICAS DIARIAS</div>
            </div>
            <div className="div-empresas">
              <HiOutlineBuildingOffice className="empresas-icono" />
              <div className="empresas-texto">{renderEmpresasInfo(edificio.empresaslistado)}</div>
            </div>
            <div className="div-empresas-destacadas">
              <TiStar className="empresas-destacadas-icono" />
              <div className="empresas-destacadas-texto">{(edificio.empresasdestacadas || "").toUpperCase()}</div>
            </div>
            <div className="linea-vertical" />
          </div>
        </div>
      ))}
    </div>
  );
};
