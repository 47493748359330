import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { getCurrentUser } from "../../utils";

export const Redirect = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const signUp = async () => {
      try {
        const user = await getCurrentUser();

        if (!user.user_metadata.apellido || !user.user_metadata.nombre) navigate("/perfil");
        else navigate("/admin");
      } catch (error) {
        alert(error.message);
      }
    };
    signUp();
  }, [navigate]);

  return <div>¿Qué hace usted aquí?</div>;
};
