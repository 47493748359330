import { log, supabase } from ".";

export const login = async ({ email, pais }) => {
  try {
    const user = await supabase.loginWithEmail(email, pais);
    log.debug("login", user);
    return user;
  } catch (error) {
    log.error("login", error);
  }
};
