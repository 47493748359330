import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getCategorias = async () => {
  try {
    const data = await supabase.select("categorias", {
      orderBy: "nombre",
      ascending: true,
    });

    if (data?.length === 0) {
      toast.error("No hay categorías cargadas en la base de datos");
      throw new Error("No data found");
    }

    return data;
  } catch (error) {
    log.error("getCategorias", error);
    throw error;
  }
};
