/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm, FormProvider } from "react-hook-form";
import { toast } from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { Container, ListaEdificios, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getClientesPublicitarios, getIntermediarios, log, saveFactura } from "../../utils";
import "./styles.scss";

export const CargarFactura = () => {
  const [clientesPublicitarios, setClientesPublicitarios] = useState();
  const [intermediarios, setIntermediarios] = useState();
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();

  const { pais } = useAuthContext();

  const methods = useForm();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = methods;

  const [intermediario, porcentajeComisionIntermediario, importeVenta, razonSocialIntermediario] = watch([
    "intermediario",
    "porcentajeComisionIntermediario",
    "importeVenta",
    "razonSocialIntermediario",
  ]);

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      const newFactura = {
        clientepublicitario: data.clientePublicitario,
        edificiosparticipantes: data.edificiosSeleccionados,
        edificiostotales: data.edificiosTotales,
        facturacobrada: false,
        fechafactura: data.fechaFactura,
        importefactura: data.importeVenta,
        intermediariocomisionporcentaje: data.porcentajeComisionIntermediario,
        intermediariorazonsocial: data.razonSocialIntermediario,
        lastupdate: new Date(),
        numerofactura: data.numeroFactura,
        pais: pais,
        pantallastotales: data.pantallasTotales,
        razonsocial: data.razonSocial,
      };

      setTemporalDataForm(newFactura);
    },
    [pais]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);
      await saveFactura(temporalDataForm);
      reset();
      setValue("edificiosTotales", 0);
      setValue("pantallasTotales", 0);
      setValue("personasTotales", "0");
      toast.success("La factura se guardó correctamente");
    } catch (error) {
      toast.error("Hubo un error al guardar la factura, prueba de nuevo.");
      log.error("saveButtonHandle", error);
    }
  }, [temporalDataForm, reset, setValue]);

  const tieneIntermediario = useMemo(() => intermediario === "si", [intermediario]);

  const fechaFacturaInicial = useMemo(() => {
    const date = new Date();

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const [month, day, year] = new Intl.DateTimeFormat("en-US", options).format(date).split("/");

    return `${year}-${month}-${day}`;
  }, []);

  const fechaProyeccionInicial = useMemo(() => {
    const date = new Date();
    date.setDate(date.getDate() + 30);

    const options = {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    };

    const [month, day, year] = new Intl.DateTimeFormat("en-US", options).format(date).split("/");

    return `${year}-${month}-${day}`;
  }, []);

  const validarSelect = useCallback((value) => value !== "0", []);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  useEffect(() => {
    const initialize = async () => {
      const newClientesPublicitarios = await getClientesPublicitarios({ pais });
      setClientesPublicitarios(newClientesPublicitarios);

      const newIntermediarios = await getIntermediarios({ pais });
      setIntermediarios(newIntermediarios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    if (intermediario === "si") {
      const newImporteVentaNeto = importeVenta - importeVenta * (porcentajeComisionIntermediario / 100);
      log.debug("newImporteVentaNeto", newImporteVentaNeto);
      setValue("importeVentaNeto", newImporteVentaNeto);
    } else {
      setValue("importeVentaNeto", importeVenta);
    }
  }, [intermediario, importeVenta, porcentajeComisionIntermediario, setValue]);

  useEffect(() => {
    const newValue = intermediarios?.find(({ nombre }) => nombre === razonSocialIntermediario)?.comision || "0";
    setValue("porcentajeComisionIntermediario", newValue);
  }, [intermediarios, razonSocialIntermediario, setValue]);

  return (
    <Container className="CargarFactura" header titulo="CARGA DE FACTURA">
      {clientesPublicitarios && intermediarios ? (
        <FormProvider {...methods}>
          <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
            <div className="col-6">
              <label htmlFor="numeroFactura" className="form-label">
                Número de Factura
              </label>
              <input
                className={cx("form-control", errors?.numeroFactura && "is-invalid")}
                id="numeroFactura"
                placeholder="Ingresa un número"
                type="text"
                {...register("numeroFactura", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-6">
              <label htmlFor="clientePublicitario" className="form-label">
                Cliente publicitario
              </label>
              <select
                className={cx("form-select", errors?.clientePublicitario && "is-invalid")}
                id="clientePublicitario"
                defaultValue="0"
                {...register("clientePublicitario", { validate: validarSelect })}
              >
                <option disabled value="0">
                  Selecciona una opción
                </option>

                {clientesPublicitarios.map(({ nombre, id }) => (
                  <option key={id} value={nombre}>
                    {nombre}
                  </option>
                ))}
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-6">
              <label htmlFor="razonSocial" className="form-label">
                Razón Social
              </label>
              <input
                className={cx("form-control", errors?.razonSocial && "is-invalid")}
                id="razonSocial"
                placeholder="Ej.: Compañía S.A."
                type="text"
                {...register("razonSocial", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-6">
              <label htmlFor="intermediario" className="form-label">
                ¿Tiene intermediario?
              </label>
              <select
                className={cx("form-select", errors?.intermediario && "is-invalid")}
                id="intermediario"
                defaultValue="0"
                {...register("intermediario", { validate: validarSelect })}
              >
                <option disabled value="0">
                  Selecciona una opción
                </option>
                <option value="si">Si</option>
                <option value="no">No</option>
              </select>

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            {tieneIntermediario && (
              <>
                <div className="col-6">
                  <label htmlFor="razonSocialIntermediario" className="form-label">
                    Seleccionar intermediario
                  </label>
                  <select
                    className={cx("form-select", errors?.razonSocialIntermediario && "is-invalid")}
                    id="razonSocialIntermediario"
                    defaultValue="0"
                    {...register("razonSocialIntermediario", { validate: validarSelect })}
                  >
                    <option disabled value="0">
                      Selecciona una opción
                    </option>
                    {intermediarios.map(({ id, nombre }) => (
                      <option key={id}>{nombre}</option>
                    ))}
                  </select>

                  <div className="invalid-feedback">Este campo es obligatorio</div>
                </div>
                <div className="col-6">
                  <label className="form-label">Comisión Intermediario</label>
                  <div className="input-group">
                    <input
                      className={cx("form-control", { "is-invalid": errors.porcentajeComisionIntermediario })}
                      defaultValue={0}
                      disabled
                      id="porcentajeComisionIntermediario"
                      type="number"
                      {...register("porcentajeComisionIntermediario")}
                    />
                    <span className="input-group-text">%</span>
                  </div>
                </div>
              </>
            )}

            <div className="col-6">
              <label htmlFor="importeVenta" className="form-label">
                Importe de la venta (sin IVA o IGV)
              </label>
              <input
                className={cx("form-control", errors?.importeVenta && "is-invalid")}
                id="importeVenta"
                placeholder="Ingresa importe"
                type="number"
                {...register("importeVenta", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>
            <div className="col-6">
              <label htmlFor="importeVentaNeto" className="form-label">
                Importe Venta Neto
              </label>
              <input
                className={cx("form-control", errors?.importeVentaNeto && "is-invalid")}
                defaultValue={0}
                disabled
                id="importeVentaNeto"
                placeholder="Ingresa un número"
                type="number"
                {...register("importeVentaNeto", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-6">
              <label htmlFor="fechaFactura" className="form-label">
                Fecha Factura
              </label>
              <input
                className={cx("form-control", errors?.fechaFactura && "is-invalid")}
                defaultValue={fechaFacturaInicial}
                id="fechaFactura"
                type="date"
                {...register("fechaFactura", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-6">
              <label htmlFor="fechaProyeccion" className="form-label">
                Fecha de cobro
              </label>
              <input
                className={cx("form-control", errors?.fechaProyeccion && "is-invalid")}
                defaultValue={fechaProyeccionInicial}
                id="fechaProyeccion"
                type="date"
                {...register("fechaProyeccion", { required: true })}
              />

              <div className="invalid-feedback">Este campo es obligatorio</div>
            </div>

            <div className="col-6">
              <label htmlFor="edificiosTotales" className="form-label">
                Cantidad de edificios seleccionados
              </label>
              <input
                className={cx("form-control", errors?.edificiosTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="edificiosTotales"
                type="number"
                {...register("edificiosTotales", { required: true })}
              />
            </div>

            <div className="col-6">
              <label htmlFor="pantallasTotales" className="form-label">
                Cantidad de pantallas seleccionadas
              </label>
              <input
                className={cx("form-control", errors?.pantallasTotales && "is-invalid")}
                defaultValue={0}
                disabled
                id="pantallasTotales"
                type="number"
                {...register("pantallasTotales", { required: true })}
              />
            </div>

            <ListaEdificios />

            <input hidden type="text" {...register("personasTotales")} />
            <input hidden type="text" {...register("edificiosSeleccionados")} />

            <div className="col-12">
              <button className="btn btn-primary" type="submit" disabled={disableSubmit}>
                {!disableSubmit ? (
                  "GUARDAR"
                ) : (
                  <>
                    <Spinner size="sm">GUARDANDO...</Spinner>
                    <span> GUARDANDO</span>
                  </>
                )}
              </button>
            </div>
          </form>

          <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
            <ModalHeader>¿Está seguro?</ModalHeader>
            <ModalBody>Se está por guardar una nueva factura</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
                Cancelar
              </Button>
              <Button color="primary" onClick={saveButtonHandle}>
                Guardar
              </Button>
            </ModalFooter>
          </Modal>
        </FormProvider>
      ) : (
        <Loader />
      )}
    </Container>
  );
};
