import { log, supabase } from ".";

export const saveCliente = async (cliente) => {
  try {
    const data = await supabase.insert("clientes", cliente);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveCliente", error);
    throw error;
  }
};
