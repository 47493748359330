/* eslint-disable no-console */
import { supabase } from ".";

export const logout = async () => {
  try {
    const result = await supabase.logout();
    if (result.success) {
      console.log("User logged out");
      // Opcional: Redireccionar al usuario después del logout
      window.location.href = "/login";
    } else {
      console.error("Failed to logout");
    }
  } catch (error) {
    console.error("Error during logout:", error);
  }
};
