/* eslint-disable unicorn/prefer-module */
import React, { memo, useMemo } from "react";
import cx from "classix";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";

export const Container = memo(({ children, className, header = false, titulo = "titulo" }) => {
  const { pais } = useAuthContext();

  const logo = useMemo(
    () =>
      ({
        Chile: require("../../img/wecast-logo.png"),
        Perú: require("../../img/visiona-logo.png"),
        Uruguay: require("../../img/screenmedia-logo.png"),
      })[pais] || require("../../img/visiona-logo.png"),
    [pais]
  );

  return (
    <main className={cx("Container", "container-xxl", className)}>
      {header && (
        <header className="header">
          <img src={logo} alt="Logo" className="logo" />
          <h1 className="titulo">{titulo}</h1>
        </header>
      )}
      {children}
    </main>
  );
});
