import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getIntermediarios = async (pais) => {
  try {
    const data = await supabase.select("intermediarios", {
      filters: pais,
      orderBy: "nombre",
      ascending: true,
    });

    if (data?.length === 0) {
      toast.error("No hay intermediarios cargados en la base de datos");
      throw new Error("No data found");
    }
    return data;
  } catch (error) {
    log.error("getIntermediarios", error);
  }
};
