/* eslint-disable prettier/prettier */
/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useAuthContext } from "../../context/auth";
import { getEdificios, updateEdificio } from "../../utils";

import "./styles.scss";

const EdificiosTable = ({ pais: propPais }) => {
  const [edificios, setEdificios] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [editMode, setEditMode] = useState(null); // Estado para controlar el modo edición
  const [editedEdificio, setEditedEdificio] = useState(null); // Estado para almacenar los datos editados

  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  useEffect(() => {
    const fetchEdificios = async () => {
      try {
        const data = await getEdificios({ pais: propPais || pais });

        // Ordenar los edificios primero por estado y luego por cantidad de personas
        const sortedData = data.sort((a, b) => {
          // Ordenar por estado
          const estadosOrder = { OPERATIVO: 1, INSTALACIÓN: 2, PROYECTO: 3 };
          if (estadosOrder[a.estado] !== estadosOrder[b.estado]) {
            return estadosOrder[a.estado] - estadosOrder[b.estado];
          }
          // Si el estado es el mismo, ordenar por cantidad de personas
          return b.personas - a.personas;
        });

        setEdificios(sortedData);
      } catch (err) {
        setError(err.message);
        toast.error(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchEdificios();
  }, [propPais, pais]);

  const handleEditClick = (edificio) => {
    setEditMode(edificio.id); // Activa el modo edición para este edificio
    setEditedEdificio(edificio); // Almacena los datos del edificio que se están editando
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedEdificio({ ...editedEdificio, [name]: value }); // Actualiza los datos editados
  };

  const handleSaveClick = async () => {
    try {
      setLoading(true);
      await updateEdificio(editedEdificio); // Llama a la función para guardar el edificio editado
      setEdificios((prevEdificios) =>
        prevEdificios.map((edificio) => (edificio.id === editedEdificio.id ? editedEdificio : edificio))
      );
      setEditMode(null); // Sal del modo edición
      toast.success("Edificio actualizado exitosamente");
    } catch {
      toast.error("Error al actualizar el edificio");
    } finally {
      setLoading(false);
    }
  };

  const handleCancelClick = () => {
    setEditMode(null); // Sal del modo edición sin guardar
    setEditedEdificio(null);
  };

  if (loading) return <div>Cargando edificios...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ maxWidth: "75%", margin: "0 auto" }}>
      <table className="table table-bordered table-striped table-hover small text-sm">
        <thead>
          <tr className="table-dark text-center">
            <th width="2%">#</th>
            <th width="15%">NOMBRE</th>
            <th width="4%">STATUS</th>
            <th width="10%">ALIANZA</th>
            <th width="5%">PERSONAS</th>
            <th width="5%">ASC.</th>
            <th width="5%">TOT.</th>
            <th width="5%">ESP.</th>
            <th width="5%">EST.</th>
            <th width="5%">EST. OCUP.</th>
            <th width="5%">ACCIONES</th> {/* Nueva columna para acciones */}
            <th width="2%">ID</th>
          </tr>
        </thead>
        <tbody>
          {edificios.map((edificio, index) => (
            <tr key={edificio.id}>
              {editMode === edificio.id ? (
                <>
                  <td>{index + 1}</td>
                  <td>
                    <input type="text" name="edificio" value={editedEdificio.edificio} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input type="text" name="estado" value={editedEdificio.estado} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input type="text" name="alianza" value={editedEdificio.alianza} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="personas"
                      value={editedEdificio.personas.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input type="text" name="pantallas" value={editedEdificio.pantallas} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input type="text" name="totems" value={editedEdificio.totems} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input type="text" name="espera" value={editedEdificio.espera} onChange={handleInputChange} />
                  </td>
                  <td>
                    <input
                      type="text"
                      name="estacionamientos"
                      value={editedEdificio.estacionamientos}
                      onChange={handleInputChange}
                    />
                  </td>
                  <td>
                    <input type="text" name="empresas" value={editedEdificio.empresas} onChange={handleInputChange} />
                  </td>
                  <td>
                    <button onClick={handleSaveClick} className="btn btn-success btn-sm">
                      Guardar
                    </button>
                    <button onClick={handleCancelClick} className="btn btn-secondary btn-sm">
                      Cancelar
                    </button>
                  </td>
                  <td>{edificio.id}</td>
                </>
              ) : (
                <>
                  <td>{index + 1}</td>
                  <td style={{ textAlign: "left" }}>{edificio.edificio.toUpperCase()}</td>
                  <td
                    style={{
                      backgroundColor:
                        edificio.estado === "OPERATIVO"
                          ? "green"
                          : edificio.estado === "INSTALACIÓN"
                            ? "red"
                            : edificio.estado === "PROYECTO"
                              ? "blue"
                              : "black",
                      color: "white",
                      textAlign: "center",
                    }}
                  >
                    {edificio.estado}
                  </td>
                  <td style={{ textAlign: "left" }}>{edificio.alianza ? edificio.alianza : "-"}</td>
                  <td>
                    {edificio.personas
                      ? edificio.personas.toLocaleString(localString(pais), {
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                      })
                      : "-"}
                  </td>
                  <td>{edificio.pantallas ? edificio.pantallas : "-"}</td>
                  <td>{edificio.totems ? edificio.totems : "-"}</td>
                  <td>{edificio.espera ? edificio.espera : "-"}</td>
                  <td>{edificio.estacionamientos ? edificio.estacionamientos : "-"}</td>
                  <td>{edificio.estacionamientos_ocupacion ? edificio.estacionamientos_ocupacion : "-"}</td>
                  <td>
                    <button
                      onClick={() => handleEditClick(edificio)}
                      className="btn btn-primary"
                      style={{ padding: "3px 6px", fontSize: "10px" }} // Estilos personalizados para hacer el botón más pequeño
                    >
                      EDIT
                    </button>
                  </td>
                  <td>{edificio.id}</td>
                </>
              )}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EdificiosTable;
