/* eslint-disable unicorn/prefer-module */
import { useEffect, useState, useMemo } from "react";
import { Container, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos } from "../../utils";
import "./styles.scss";

function Inventario() {
  const [edificios, setEdificios] = useState();
  const [searchTerm, setSearchTerm] = useState(""); // Estado para el término de búsqueda

  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  const filteredEdificios = useMemo(() => {
    if (!edificios) return [];
    return [...edificios]
      .filter(({ empresaslistado }) =>
        empresaslistado ? empresaslistado.toUpperCase().includes(searchTerm.toUpperCase()) : false
      )
      .sort((a, b) => a.edificio.localeCompare(b.edificio));
  }, [edificios, searchTerm]);

  const calculateEmpresasCantidad = (empresaslistado) => {
    if (!empresaslistado) {
      return 0;
    }

    if (empresaslistado.toUpperCase() === "NO DISPONIBLE") {
      return 0;
    }

    const empresasArray = empresaslistado.split(",");
    return empresasArray.length;
  };

  const totalEmpresasCantidad = useMemo(() => {
    if (edificios && edificios.length > 0) {
      return edificios.reduce((total, edificio) => total + calculateEmpresasCantidad(edificio.empresaslistado), 0);
    }
    return 0;
  }, [edificios]);

  // Función para resaltar el texto encontrado
  const highlightText = (text, highlight) => {
    if (!highlight) return text; // Si no hay término de búsqueda, devuelve el texto sin cambios
    const regex = new RegExp(`(${highlight})`, "gi"); // Crear una expresión regular para el término de búsqueda
    const parts = text.split(regex); // Dividir el texto en partes, manteniendo las coincidencias
    return parts.map((part, index) =>
      part.toLowerCase() === highlight.toLowerCase() ? (
        <span key={index} className="highlight">
          {part}
        </span> // Envolver las coincidencias en un span
      ) : (
        part
      )
    );
  };

  return (
    <Container className="Admin" header titulo="EMPRESAS POR EDIFICIO">
      {edificios ? (
        <>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "1rem" }}>
            <input
              type="text"
              placeholder="Buscar empresa..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="form-control"
              style={{ width: "50%" }} // Mantén el estilo del ancho
            />
          </div>
          <div className="table-responsive mx-auto">
            <table className="table table-striped table-hover table-bordered table-sm">
              <thead className="table-dark align-middle">
                <tr>
                  <th scope="col" className="col-2">
                    EDIFICIO
                  </th>
                  <th scope="col" className="col-1 text-center">
                    CANTIDAD
                  </th>
                  <th scope="col" className="col text-center">
                    EMPRESAS
                  </th>
                </tr>
              </thead>
              <tbody className="table-striped align-middle">
                {filteredEdificios.map(({ id, edificio, empresaslistado }) => (
                  <tr key={id}>
                    <td className="col-2 text-start">{edificio.toUpperCase()}</td>
                    <td className="col-1 text-center">{calculateEmpresasCantidad(empresaslistado)}</td>
                    <td className="col text-center">
                      {empresaslistado ? highlightText(empresaslistado.toUpperCase(), searchTerm) : ""}
                    </td>
                  </tr>
                ))}
              </tbody>
              <tfoot>
                <tr className="table-danger text-center align-middle">
                  <td>TOTAL</td>
                  <td>{totalEmpresasCantidad}</td>
                  <td> </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </>
      ) : (
        <Loader />
      )}
    </Container>
  );
}

export default Inventario;
