import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getEdificiosOperativos = async ({ pais }) => {
  try {
    // Asumiendo que supabase.select es la forma correcta de hacer la consulta en tu configuración
    const data = await supabase.select("edificios", {
      filters: {
        pais,
        // Separamos la lógica de filtrado para asegurarnos de que solo se incluyan los estados deseados
      },
      orderBy: "pantallas",
      ascending: false,
    });

    // Filtramos manualmente los estados 'OPERATIVO' y 'INSTALACIÓN'
    const filteredData = data.filter((edificio) => ["OPERATIVO", "INSTALACIÓN"].includes(edificio.estado));

    if (filteredData.length === 0) {
      toast.error("No hay edificios cargados en la base de datos");
      throw new Error("No data found");
    }

    return filteredData;
  } catch (error) {
    log.error("getEdificiosOperativos", error);
    throw error;
  }
};
