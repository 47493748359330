import toast from "react-hot-toast";
import { supabase } from ".";

export const getFacturasComisionTodos = async ({ edificios, fechas }) => {
  try {
    // Filtramos las facturas que coincidan con cualquier edificio de la lista 'edificios'
    const data = await supabase.select("sales", {
      filters: {
        facturafecha: { gte: fechas[0], lte: fechas[1] },
        edificiosparticipantes: { in: edificios }, // Filtrar para múltiples edificios
        eliminada: !true,
      },
    });

    if (data?.length === 0) {
      toast.error("No hay facturas asociadas a los edificios en las fechas seleccionadas");
      throw new Error("No data found");
    }

    return data;
  } catch {
    return [];
  }
};
