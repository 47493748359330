import React, { useState } from "react";
import "./styles.scss";

export const CotizadorProgrammaticFrecuencia = ({ onFrecuenciaChange, isProgrammaticSelected, isDigitalSelected }) => {
  const [selectedPaquete, setSelectedPaquete] = useState("");

  const handlePaqueteChange = (event) => {
    setSelectedPaquete(event.target.value);
    onFrecuenciaChange(event.target.value);
  };

  return (
    <div className="frecuencia">
      <p className="titulofrecuencia">FRECUENCIA</p>
      <hr />
      <p className="subtitulo">SALIDAS POR HORA</p>
      <hr />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "5"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="5"
        />
        5 {isDigitalSelected && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          checked={selectedPaquete === "10"}
          name="paquete"
          onChange={handlePaqueteChange}
          type="checkbox"
          value="10"
        />
        10 {isDigitalSelected && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "15"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="15"
        />
        15 {isDigitalSelected && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "20"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="20"
        />
        20 {isProgrammaticSelected && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "25"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="25"
        />
        25 {isProgrammaticSelected && "(recomendado)"}
      </label>
      <br />
      <label className="checkboxfrecuencia">
        <input
          checked={selectedPaquete === "30"}
          name="paquete"
          onChange={handlePaqueteChange}
          style={{ width: "15px", height: "15px", marginRight: "10px", alignContent: "center" }}
          type="checkbox"
          value="30"
        />
        30 {isProgrammaticSelected && "(recomendado)"}
      </label>
      <br />
    </div>
  );
};
