export const quitarCaracteresEspeciales = (texto, toMayus = true) => {
  const mapaTildes = {
    á: "a",
    é: "e",
    í: "i",
    ó: "o",
    ú: "u",
    ü: "u",
    ñ: "n",
  };

  if (toMayus) texto = texto.toUpperCase();

  return texto.replace(/[áéíñóúü]/g, (letra) => mapaTildes[letra] || letra);
};
