import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getEdificios = async ({ pais }) => {
  try {
    const data = await supabase.select("edificios", {
      filters: { pais },
      orderBy: [
        { column: "estado", ascending: true },
        { column: "pantallas", ascending: false },
      ],
    });

    if (data?.length === 0) {
      toast.error("No hay edificios cargados en la base de datos");
      throw new Error("No data found");
    }

    return data;
  } catch (error) {
    log.error("getEdificios", error);
    throw error;
  }
};
