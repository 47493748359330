/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable unicorn/prefer-module */
import React, { useMemo } from "react";
import { useAuthContext } from "../../context/auth";
import "./styles.scss";
import fondoChile from "../../img/fondowecastbyreimpulso.png";
import logoUruguay from "../../img/screenmedia-logo-transparente.png";
import logoPeru from "../../img/visiona-logo-transparente.png";
import logoChile from "../../img/wecastbyreimpulso.svg";

export const Home = () => {
  const { pais } = useAuthContext();

  const logo = useMemo(() => {
    switch (pais) {
      case "Chile":
        return logoChile;
      case "Perú":
        return logoPeru;
      case "Uruguay":
        return logoUruguay;
      default:
        return logoPeru;
    }
  }, [pais]);

  const mail = useMemo(
    () =>
      ({
        Chile: "hola@wecast.cl",
        Perú: "hola@visiona.pe",
        Uruguay: "hola@screenmedia.com.uy",
      })[pais] || "hola@visiona.pe",
    [pais]
  );

  const uruguayUrl = "https://reimpulso.uy"; // Reemplaza con la URL deseada

  if (pais === "Chile") {
    return (
      <main className="Home" style={{ backgroundImage: `url(${fondoChile})` }}>
        <div className="container">
          <div className="container-auxiliar">
            <img src={logo} alt="Logo" className="logo" />
            <p className="texto-titulo ">¡Estamos trabajando en algo nuevo! </p>
            <p className="texto">
              Construimos nuestro sitio web para ofrecerte la mejor experiencia y mostrar nuestras soluciones digitales.
              Mientras tanto te invitamos a explorar nuestros proyectos en Uruguay.
            </p>
            <p className="texto">
              Más información: {""}
              <a href={uruguayUrl} style={{ fontSize: "1.5rem", color: "#2ce67c", textDecoration: "none" }}>
                reimpulso.uy
              </a>
            </p>
            <p className="correo">
              Contactanos: {""}
              <a href={`mailto:${mail}`} style={{ color: "white", textDecoration: "none" }}>
                {mail}
              </a>
            </p>
          </div>
        </div>
      </main>
    );
  }

  return (
    <main className="Home">
      <div className="container">
        <div style={{ zIndex: "1", backdropFilter: "blur(1px)" }}>
          <img src={logo} alt="Logo" />
          <p style={{ fontSize: "3.5rem", width: "100%", backdropFilter: "blur(1px)", marginTop: "4rem" }}>
            ¡Estamos trabajando en algo nuevo!
          </p>
          <p style={{ fontSize: "2rem", backdropFilter: "blur(1px)", marginTop: "2rem" }}>
            Mientras, puedes contactarnos aquí: {""}
            <a href={`mailto:${mail}`} style={{ color: "white", textDecoration: "none", fontSize: "2rem" }}>
              {mail}
            </a>
          </p>
        </div>
      </div>
    </main>
  );
};
