import { StyleSheet, Font } from "@react-pdf/renderer";

// // Register Font
Font.register({
  family: "Oswald",
  src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
});

// Create styles
const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    fontFamily: "Oswald",
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center",
    color: "red",
  },
  title: {
    fontSize: 25,
    textAlign: "center",
    marginBottom: 15,
  },
  row: {
    fontSize: 12,
    flexDirection: "row",
    width: "100vw",
    marginBottom: 7,
  },
  margin2: {
    marginLeft: "25vw",
    width: "25vw",
  },
  margin3: {
    width: "25vw",
    textAlign: "right",
  },
  margin4: {
    width: "12.5vw",
    textAlign: "right",
  },
  margin5: {
    width: "12.5vw",
    textAlign: "right",
    color: "red",
  },
  margin6: {
    marginLeft: "25vw",
    width: "25vw",
    borderTop: "1px",
    marginTop: 2,
    paddingTop: 2,
  },
  margin7: {
    width: "25vw",
    textAlign: "right",
    borderTop: "1px",
    marginTop: 2,
    paddingTop: 2,
  },
  letrachica1: {
    fontSize: 10,
    width: "45vw",
    margin: "auto",
    marginTop: 5,
    marginBottom: 10,
    textAlign: "center",
  },
  letrachica2: {
    fontSize: 8,
    width: "48vw",
    margin: "auto",
    marginTop: 5,
    marginBottom: 8,
    textAlign: "center",
  },
  image: {
    height: "8%",
    width: "8%",
    marginTop: 10,
    marginBottom: 5,
    marginHorizontal: "auto",
  },
  mail: {
    fontSize: 8,
    textAlign: "center",
    paddingBottom: 5,
  },
});

export default styles;
