import { log, supabase } from ".";

export const updateProfile = async ({ apellido, nombre }) => {
  try {
    const newUserData = {
      apellido,
      nombre,
    };
    const { data, error } = await supabase.updateProfile(newUserData);

    if (error) throw error;

    if (data) {
      log.debug("updateProfile", data);
      return data;
    } else throw new Error("No data");
  } catch (error) {
    log.error("updateProfile", error);
    throw error;
  }
};
