import { supabase } from ".";

export const saveEdificio = async (edificio) => {
  try {
    const data = await supabase.insert("edificios", edificio);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveEdificio", error);
    throw error;
  }
};
