// Asegúrate de importar el objeto supabase
import { log, supabase } from ".";

export const updateEdificio = async ({ id, totems }) => {
  try {
    // Utiliza la función personalizada 'update'
    const data = await supabase.update("edificios", { totems: totems }, id);

    // Log de depuración
    log.debug("updateEdificio", data);
    return data;
  } catch (error) {
    log.error("updateEdificio", error);
    throw error;
  }
};
