import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { BlackArrow, Container } from "../../components";
import { useAuthContext } from "../../context/auth";
import { log, quitarCaracteresEspeciales, saveIntermediario } from "../../utils";

export const CargarIntermediario = () => {
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();

  const { pais } = useAuthContext();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const rucrut = useCallback(
    (pais) =>
      ({
        Chile: "RUT",
        Perú: "RUC",
        Uruguay: "RUT",
      })[pais] || "RUC",
    []
  );

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      const newIntermediario = {
        comision: data.comision,
        lastupdate: new Date(),
        nombre: quitarCaracteresEspeciales(data.nombre),
        pais,
        razonsocial: data.razonsocial.toUpperCase(),
        rut: data.rut,
      };

      setTemporalDataForm(newIntermediario);
    },
    [pais]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);
      await saveIntermediario(temporalDataForm);
      reset();
      toast.success("El intermediario se guardó correctamente");
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "El intermediario ya existe" });
      log.error("saveButtonHandle", error);
      toast.error("Hubo un error al guardar el intermediario, prueba de nuevo.");
    }
  }, [temporalDataForm, reset, setError]);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  return (
    <>
      <BlackArrow />
      <Container className="CargarIntermediario" header titulo="CARGAR AGENCIA DE MEDIOS">
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-6">
            <label htmlFor="nombre" className="form-label">
              Nombre
            </label>
            <input
              className={cx("form-control", errors?.nombre && "is-invalid")}
              id="nombre"
              placeholder="-"
              type="text"
              {...register("nombre", { required: "Este campo es obligatorio" })}
            />

            <div className="invalid-feedback">{errors?.nombre?.message}</div>
          </div>

          <div className="col-6">
            <label htmlFor="comision" className="form-label">
              Comisión
            </label>
            <input
              className={cx("form-control", errors?.comision && "is-invalid")}
              id="comision"
              placeholder="-"
              type="number"
              {...register("comision", { required: true })}
            />

            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-6">
            <label htmlFor="razonsocial" className="form-label">
              Razón Social
            </label>
            <input
              className={cx("form-control", errors?.razonsocial && "is-invalid")}
              id="razonsocial"
              placeholder="-"
              type="text"
              {...register("razonsocial", { required: true })}
            />

            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-6">
            <label htmlFor="rut" className="form-label">
              {rucrut(pais)}
            </label>
            <input
              className={cx("form-control", errors?.rut && "is-invalid")}
              id="rut"
              placeholder="-"
              type="number"
              {...register("rut", { required: true })}
            />

            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-primary" type="submit">
              {!disableSubmit ? (
                "GUARDAR"
              ) : (
                <>
                  <Spinner size="sm">GUARDANDO...</Spinner>
                  <span> GUARDANDO</span>
                </>
              )}
            </button>
          </div>
        </form>

        <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
          <ModalHeader>¿Está seguro?</ModalHeader>
          <ModalBody>Se está por guardar una nueva agencia de medios</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
              Cancelar
            </Button>
            <Button color="primary" onClick={saveButtonHandle}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};
