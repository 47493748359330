import { supabase } from ".";

export const saveOportunidad = async (oportunidad) => {
  try {
    const data = await supabase.insert("sales", oportunidad);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveOportunidad", error);
    throw error;
  }
};
