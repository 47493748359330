import { useCallback, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import cx from "classix";
import { BlackArrow, Container } from "../../components";
import { useAuthContext } from "../../context/auth";
import { log, saveEdificio } from "../../utils";

export const CargarEdificio = () => {
  const [showModalConfirmacion, setShowModalConfirmacion] = useState(false);
  const [temporalDataForm, setTemporalDataForm] = useState();

  const { pais } = useAuthContext();

  const {
    formState: { errors },
    handleSubmit,
    register,
    reset,
    setError,
  } = useForm();

  const onSubmit = useCallback(
    (data) => {
      setShowModalConfirmacion(true);

      const newEdificio = {
        comision_1_fechafin: data.comision_1_fechafin,
        comision_1_fechainicio: data.comision_1_fechainicio,
        comision_1_porcentaje: data.comision_1_porcentaje,
        comision_2_fechafin: data.comision_2_fechafin || null,
        comision_2_fechainicio: data.comision_2_fechainicio || null,
        comision_2_porcentaje: data.comision_2_porcentaje || null,
        direccion: data.direccion,
        edificio: data.edificio,
        empresasdestacadas: data.empresasdestacadas,
        empresaslistado: data.empresaslistado,
        espera: data.espera,
        estacionamientos: data.estacionamientos,
        estado: data.estado,
        lat: data.latitud,
        lng: data.longitud,
        pantallas: data.pantallas,
        personas: data.personas,
        programatica: data.programatica,
        totems: data.totems,
        ubicacion: data.ubicacion,
        pais,
      };

      setTemporalDataForm(newEdificio);
    },
    [pais]
  );

  const saveButtonHandle = useCallback(async () => {
    try {
      setShowModalConfirmacion(false);

      // Filtrar los campos nulos o indefinidos antes de enviar a Supabase
      const filteredData = Object.fromEntries(
        Object.entries(temporalDataForm).filter(([key, value]) => value !== null && value !== undefined)
      );

      await saveEdificio(filteredData); // Usamos los datos filtrados
      reset();
      toast.success("El edificio se guardó correctamente");
    } catch (error) {
      if (error.code === "23505") setError("nombre", { type: "custom", message: "El edificio ya existe" });
      log.error("saveButtonHandle", error);
      toast.error("Hubo un error al guardar el edificio, prueba de nuevo.");
    }
  }, [temporalDataForm, reset, setError]);

  const disableSubmit = useMemo(() => showModalConfirmacion, [showModalConfirmacion]);

  return (
    <>
      <BlackArrow />
      <Container className="CargarEdificio" header titulo="CARGAR EDIFICIO">
        <form className="row g-3" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-2">
            <label htmlFor="edificio" className="form-label">
              Nombre
            </label>
            <input
              className={cx("form-control", errors?.edificio && "is-invalid")}
              id="edificio"
              placeholder="-"
              type="text"
              {...register("edificio", { required: "Este campo es obligatorio" })}
            />
            <div className="invalid-feedback">{errors?.nombre?.message}</div>
          </div>
          <div className="col-2">
            <label htmlFor="estado" className="form-label">
              Status
            </label>
            <select
              className={cx("form-control", errors?.estado && "is-invalid")}
              id="estado"
              {...register("estado", { required: true })}
            >
              <option value="">-</option>
              <option value="OPERATIVO">OPERATIVO</option>
              <option value="INSTALACIÓN">INSTALACIÓN</option>
              <option value="PROYECTO">PROYECTO</option>
            </select>
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="personas" className="form-label">
              Audiencia
            </label>
            <input
              className={cx("form-control", errors?.personas && "is-invalid")}
              id="personas"
              placeholder="-"
              type="number"
              {...register("personas", {
                required: "Este campo es obligatorio",
                min: {
                  value: 1,
                  message: "Debe ingresar un número positivo",
                },
                validate: (value) => value % 10 === 0 || "El número debe terminar en 0",
              })}
            />
            <div className="invalid-feedback">{errors?.personas?.message || "Este campo es obligatorio"}</div>
          </div>

          <div className="col-2">
            <label htmlFor="pantallas" className="form-label">
              Pantallas en Ascensores
            </label>
            <input
              className={cx("form-control", errors?.pantallas && "is-invalid")}
              id="pantallas"
              placeholder="-"
              type="number"
              {...register("pantallas", {
                required: true,
                min: {
                  value: 1,
                  message: "Debe ingresar un número positivo",
                },
              })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="totems" className="form-label">
              Pantallas de Circulación
            </label>
            <input
              className={cx("form-control", errors?.totems && "is-invalid")}
              id="totems"
              placeholder="-"
              type="number"
              {...register("totems", {
                required: true,
                min: {
                  value: 1,
                  message: "Debe ingresar un número positivo",
                },
              })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="Espera" className="form-label">
              Pantallas de Espera
            </label>
            <input
              className={cx("form-control", errors?.Espera && "is-invalid")}
              id="Espera"
              placeholder="-"
              type="number"
              {...register("Espera", {
                required: true,
                min: {
                  value: 1,
                  message: "Debe ingresar un número positivo",
                },
              })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="programatica" className="form-label">
              Programática
            </label>
            <select
              className={cx("form-control", errors?.programatica && "is-invalid")}
              id="programatica"
              {...register("programatica", {
                required: "Este campo es obligatorio",
              })}
            >
              <option value="">-</option> {/* Opción por defecto */}
              <option value="Sí">Sí</option>
              <option value="No">No</option>
            </select>
            <div className="invalid-feedback">{errors?.edificio?.message}</div>
          </div>
          <div className="col-2">
            <label htmlFor="estacionamientos" className="form-label">
              Estacionamientos
            </label>
            <input
              className={cx("form-control", errors?.estacionamientos && "is-invalid")}
              id="estacionamientos"
              type="number"
              min="1" // Acepta solo números mayores a cero
              placeholder="0"
              {...register("estacionamientos", {
                required: true,
                min: {
                  value: 1,
                  message: "El número debe ser mayor a cero",
                },
              })}
            />
            <div className="invalid-feedback">{errors?.estacionamientos?.message || "Este campo es obligatorio"}</div>
          </div>
          <div className="col-2">
            <label htmlFor="direccion" className="form-label">
              Dirección
            </label>
            <input
              className={cx("form-control", errors?.direccion && "is-invalid")}
              id="direccion"
              placeholder="-"
              type="text" // Cambiado a tipo texto
              {...register("direccion", {
                required: "Este campo es obligatorio",
                pattern: {
                  value: /^[\d\sA-Za-z]+$/,
                  message: "Solo se permiten letras, números y espacios",
                },
              })}
            />
            <div className="invalid-feedback">{errors?.direccion?.message || "Este campo es obligatorio"}</div>
          </div>
          <div className="col-2">
            <label htmlFor="ubicacion" className="form-label">
              Ubicación
            </label>
            <select
              className={cx("form-control", errors?.ubicacion && "is-invalid")}
              id="ubicacion"
              {...register("ubicacion", { required: "Este campo es obligatorio" })}
            >
              <option value="">-</option> {/* Opción vacía o predeterminada */}
              <option value="Las Condes">Las Condes</option>
              <option value="Providencia">Providencia</option>
              <option value="Centro">Centro</option>
              <option value="Vitacura">Vitacura</option>
              <option value="Lo Barnechea">Lo Barnechea</option>
              <option value="Golf">Golf</option>
            </select>
            <div className="invalid-feedback">{errors?.ubicacion?.message || "Este campo es obligatorio"}</div>
          </div>
          <div className="col-2">
            <label htmlFor="latitud" className="form-label">
              Latitud
            </label>
            <input
              className={cx("form-control", errors?.latitud && "is-invalid")}
              id="latitud"
              placeholder="-"
              type="number"
              step="any" // Permite muchos decimales
              {...register("latitud", { required: "Este campo es obligatorio" })}
            />
            <div className="invalid-feedback">{errors?.latitud?.message || "Este campo es obligatorio"}</div>
          </div>

          <div className="col-2">
            <label htmlFor="longitud" className="form-label">
              Longitud
            </label>
            <input
              className={cx("form-control", errors?.longitud && "is-invalid")}
              id="longitud"
              placeholder="-"
              type="number"
              step="any" // Permite muchos decimales
              {...register("longitud", { required: "Este campo es obligatorio" })}
            />
            <div className="invalid-feedback">{errors?.longitud?.message || "Este campo es obligatorio"}</div>
          </div>

          <div className="col-2">
            <label htmlFor="comision_1_porcentaje" className="form-label">
              Comisión 1
            </label>
            <input
              className={cx("form-control", errors?.comision_1_porcentaje && "is-invalid")}
              id="comision_1_porcentaje"
              placeholder="-"
              type="number"
              {...register("comision_1_porcentaje", { required: true })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="comision_1_fechainicio" className="form-label">
              Comisión 1 fecha inicio
            </label>
            <input
              className={cx("form-control", errors?.comision_1_fechainicio && "is-invalid")}
              id="comision_1_fechainicio"
              placeholder="-"
              type="date" // Cambiado a tipo date
              {...register("comision_1_fechainicio", { required: "Este campo es obligatorio" })}
            />
            <div className="invalid-feedback">
              {errors?.comision_1_fechainicio?.message || "Este campo es obligatorio"}
            </div>
          </div>
          <div className="col-2">
            <label htmlFor="comision_1_fechafin" className="form-label">
              Comisión 1 fecha fin
            </label>
            <input
              className={cx("form-control", errors?.comision_1_fechafin && "is-invalid")}
              id="comision_1_fechafin"
              placeholder="-"
              type="date" // Cambiado a tipo date
              {...register("comision_1_fechafin", { required: "Este campo es obligatorio" })}
            />
            <div className="invalid-feedback">
              {errors?.comision_1_fechafin?.message || "Este campo es obligatorio"}
            </div>
          </div>
          <div className="col-2">
            <label htmlFor="comision_2_porcentaje" className="form-label">
              Comisión 2
            </label>
            <input
              className={cx("form-control", errors?.comision_2_porcentaje && "is-invalid")}
              id="comision_2_porcentaje"
              placeholder="-"
              type="number"
              {...register("comision_2_porcentaje", { required: false })}
            />
            <div className="invalid-feedback">Este campo es obligatorio</div>
          </div>
          <div className="col-2">
            <label htmlFor="comision_2_fechainicio" className="form-label">
              Comisión 2 fecha inicio
            </label>
            <input
              className={cx("form-control", errors?.comision_2_fechainicio && "is-invalid")}
              id="comision_2_fechainicio"
              placeholder="-"
              type="date" // Cambiado a tipo date
              {...register("comision_2_fechainicio", { required: false })}
            />
            <div className="invalid-feedback">{errors?.comision_2_fechainicio?.message || false}</div>
          </div>
          <div className="col-2">
            <label htmlFor="comision_2_fechafin" className="form-label">
              Comisión 2 fecha fin
            </label>
            <input
              className={cx("form-control", errors?.comision_2_fechafin && "is-invalid")}
              id="comision_2_fechafin"
              placeholder="-"
              type="date" // Cambiado a tipo date
              {...register("comision_2_fechafin", { required: false })}
            />
            <div className="invalid-feedback">
              {errors?.comision_2_fechafin?.message || "Este campo es obligatorio"}
            </div>
          </div>
          <div className="col-6">
            <label htmlFor="empresaslistado" className="form-label">
              Empresas listado
            </label>
            <input
              className={cx("form-control", errors?.empresaslistado && "is-invalid")}
              id="empresaslistado"
              placeholder="Ingrese los nombres separados por coma"
              type="text" // Tipo texto
              {...register("empresaslistado", {
                required: "Este campo es obligatorio",
                pattern: {
                  value: /^[\d\s,.A-Za-z]+$/, // Acepta letras, números, espacios, comas y puntos
                  message: "Ingrese nombres válidos, separados por coma",
                },
              })}
            />
            <div className="invalid-feedback">{errors?.empresaslistado?.message || "Este campo es obligatorio"}</div>
          </div>
          <div className="col-6">
            <label htmlFor="empresasdestacadas" className="form-label">
              Empresas destacadas
            </label>
            <input
              className={cx("form-control", errors?.empresasdestacadas && "is-invalid")}
              id="empresasdestacadas"
              placeholder="Ingrese los nombres separados por coma"
              type="text" // Tipo texto
              {...register("empresasdestacadas", {
                required: "Este campo es obligatorio",
                pattern: {
                  value: /^[\d\s,.A-Za-z]+$/, // Acepta letras, números, espacios, comas y puntos
                  message: "Ingrese nombres válidos, separados por coma",
                },
              })}
            />
            <div className="invalid-feedback">{errors?.empresasdestacadas?.message || "Este campo es obligatorio"}</div>
          </div>

          <div className="col-12 d-flex justify-content-center">
            <button className="btn btn-primary" type="submit">
              {!disableSubmit ? (
                "GUARDAR"
              ) : (
                <>
                  <Spinner size="sm">GUARDANDO...</Spinner>
                  <span> GUARDANDO</span>
                </>
              )}
            </button>
          </div>
        </form>

        <Modal isOpen={showModalConfirmacion} toggle={() => setShowModalConfirmacion(false)}>
          <ModalHeader>¿Está seguro?</ModalHeader>
          <ModalBody>Se está por guardar un nuevo edificio</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={() => setShowModalConfirmacion(false)}>
              Cancelar
            </Button>
            <Button color="primary" onClick={saveButtonHandle}>
              Guardar
            </Button>
          </ModalFooter>
        </Modal>
      </Container>
    </>
  );
};
