import React, { useState, useRef, useEffect } from "react";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Ayuda = ({ textoAyuda }) => {
  const [mostrarAyuda, setMostrarAyuda] = useState(false);
  const ayudaRef = useRef(null);
  const [anchoMinimo, setAnchoMinimo] = useState("auto");

  const toggleAyuda = () => {
    setMostrarAyuda(!mostrarAyuda);
  };

  const handleClickOutside = (event) => {
    if (ayudaRef.current && !ayudaRef.current.contains(event.target)) {
      setMostrarAyuda(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (mostrarAyuda && ayudaRef.current) {
      const ayudaWidth = ayudaRef.current.offsetWidth;
      const iconWidth = ayudaRef.current.parentElement.offsetWidth;
      const leftPosition = -((ayudaWidth - iconWidth) / 2);
      const topPosition = ayudaRef.current.parentElement.offsetHeight + 10;
      ayudaRef.current.style.left = `${leftPosition}px`;
      ayudaRef.current.style.top = `${topPosition}px`;

      // Determinar el ancho mínimo necesario
      const maxWidthLinea = textoAyuda.reduce((max, linea) => Math.max(max, linea.length), 0);
      const anchoMinimoCalculado = maxWidthLinea * 7 + 10; // Considerando 8px por caracter y 20px de margen
      setAnchoMinimo(anchoMinimoCalculado + "px");
    }
  }, [mostrarAyuda, textoAyuda]);

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <FontAwesomeIcon icon={faQuestionCircle} onClick={toggleAyuda} style={{ cursor: "pointer" }} />
      {mostrarAyuda && (
        <div
          ref={ayudaRef}
          style={{
            border: "1px solid black",
            backgroundColor: "white",
            position: "absolute",
            padding: "10px",
            fontSize: "12px",
            fontFamily: "Trebuchet MS, sans-serif",
            borderRadius: "5px",
            zIndex: 9999,
            minWidth: anchoMinimo, // Establecer el ancho mínimo calculado
            maxWidth: "300px", // Ajusta el ancho máximo según lo necesites
            whiteSpace: "pre-wrap", // Permitir salto de línea
            lineHeight: "1.5", // Ajustar el espacio entre líneas
          }}
        >
          {textoAyuda.map((linea, index) => (
            <div key={index}>{linea}</div>
          ))}
        </div>
      )}
    </div>
  );
};

export default Ayuda;
