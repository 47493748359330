import React from "react";
import { useState, useEffect } from "react";
import { log } from "../../utils";

function Api({ session }) {
  const [resultados, setResultados] = useState();

  useEffect(() => {
    const getApi = async () => {
      try {
        const url =
          "https://min-api.cryptocompare.com/data/price?fsym=BTC&tsyms=USD,JPY,EUR&api_key=e4361c1bdf4ace9b8300ce968a91901d2d1587fa30aa3ec29871dc54960b701b";
        const request = await fetch(url);
        const data = await request.json();

        log.debug("data", data);
        log.debug("request", request);

        if (data) {
          setResultados(data);
        }
      } catch (error) {
        log.error("getUsers", error);
      }
    };
    getApi();
  }, []);

  return <div> {resultados && resultados.USD}</div>;
}

export default Api;
