import { log, supabase } from ".";

export const getSession = async () => {
  try {
    const session = await supabase.getSession();

    if (session !== undefined) return session;
    else throw new Error("No user session found");
  } catch (error) {
    log.error("getSession", error);
    throw error;
  }
};
