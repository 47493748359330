import toast from "react-hot-toast";
import { log, supabase } from "."; // Asegúrate de importar correctamente el cliente de Supabase y el log

export const getCotizaciones = async ({ pais }) => {
  try {
    const data = await supabase.select("cotizaciones", {
      filters: { pais },
      orderBy: [
        { column: "created_at", ascending: false }, // Ordenar por fecha de creación
      ],
    });

    if (!data || data.length === 0) {
      toast.error("No hay cotizaciones cargadas en la base de datos");
      throw new Error("No data found");
    }

    log.debug("getCotizaciones", data);
    return data;
  } catch (error) {
    log.error("getCotizaciones", error);
    throw error;
  }
};
