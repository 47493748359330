import { log, supabase } from ".";

export const saveFactura = async (factura) => {
  try {
    const data = await supabase.insert("facturacion", factura);

    if (!data || data.length === 0) throw new Error("Empty response");

    return data;
  } catch (error) {
    log.error("saveFactura", error);
    throw error;
  }
};
