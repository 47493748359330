/* eslint-disable no-console */
import { useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { BlackArrow, Container, ListadoFacturas, Loader } from "../../components";
import { useAuthContext } from "../../context/auth";
import { getEdificiosOperativos, getFacturasComision2, log } from "../../utils";
import "./styles.scss";

export const CalculadorComisionEdificioTodosTest = () => {
  const [edificios, setEdificios] = useState();
  const [facturas, setFacturas] = useState();

  const { pais, edificiosOk } = useAuthContext() || {};

  const { handleSubmit, register, watch } = useForm({ defaultValues: { edificio: "0" } });

  const [edificioSeleccionado, fechaInicio, fechaFin] = watch(["edificio", "fechaInicio", "fechaFin"]);

  const regexFecha = useMemo(() => /^(20\d{2})-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01])$/, []);

  const onSubmit = useCallback(
    async (data) => {
      try {
        const edificio = edificios.find((ed) => ed.id === Number(data.edificio));
        const listaFacturas = await getFacturasComision2({
          edificio: data.edificio,
          fechas: [data.fechaInicio, data.fechaFin],
        });

        // Calcular el total de edificios participantes y pantallas involucradas
        const edificiosParticipantes = listaFacturas.reduce(
          (total, factura) => total + (factura.edificiostotales || 0),
          0
        );
        const pantallasInvolucradas = listaFacturas.reduce(
          (total, factura) => total + (factura.pantallastotales || 0),
          0
        );

        const newFacturas = {
          comisionEdificio_1_porcentaje: edificio.comision_1_porcentaje,
          comisionEdificio_1_fechainicio: edificio.comision_1_fechainicio,
          comisionEdificio_1_fechafin: edificio.comision_1_fechafin,
          comisionEdificio_2_porcentaje: edificio.comision_2_porcentaje,
          comisionEdificio_2_fechainicio: edificio.comision_2_fechainicio,
          comisionEdificio_2_fechafin: edificio.comision_2_fechafin,
          comisionEdificio_3_porcentaje: edificio.comision_3_porcentaje,
          comisionEdificio_3_fechainicio: edificio.comision_3_fechainicio,
          comisionEdificio_3_fechafin: edificio.comision_3_fechafin,
          listado: listaFacturas,
          pantallasEdificio: edificio.pantallas,
          edificiosParticipantes,
          pantallasInvolucradas,
        };
        setFacturas(newFacturas);
      } catch (error) {
        log.error("CalculadorComisionEdificio", error);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    [edificios]
  );

  const forceSubmit = useCallback(() => handleSubmit(onSubmit)(), [handleSubmit, onSubmit]);

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificiosOperativos({ pais });
      newEdificios.sort((a, b) => a.edificio.localeCompare(b.edificio));
      setEdificios(newEdificios);
    };

    initialize();
  }, [pais]);

  useEffect(() => {
    if (edificioSeleccionado !== "0" && regexFecha.test(fechaInicio) && regexFecha.test(fechaFin)) forceSubmit();
  }, [forceSubmit, regexFecha, edificioSeleccionado, fechaInicio, fechaFin]);

  if (!edificios) return <Loader />;
  if (!pais) return <Loader />;

  return (
    <>
      <BlackArrow />
      <Container className="CalculadorComisionEdificio" header titulo="COMISIÓN EDIFICIOS TODOS TEST">
        <form className="row g-3 mb-5" onSubmit={handleSubmit(onSubmit)}>
          <div className="col-4">
            <div className="form-floating">
              <select className="form-select" {...register("edificio")}>
                <option disabled value="0">
                  Selecciona una opción
                </option>
                {edificios
                  .filter((edificio) => {
                    // Si edificiosOk es NULL, mostrar todos los edificios
                    if (!edificiosOk) return true;
                    // Si edificiosOk no es NULL, filtrar los edificios por los IDs incluidos en edificiosOk
                    return edificiosOk.includes(edificio.id);
                  })
                  .map((edificio) => (
                    <option key={edificio.id} value={edificio.id}>
                      {edificio.edificio}
                    </option>
                  ))}
              </select>
              <label>1. Edificio</label>
            </div>
          </div>

          <div className="col-4">
            <div className="form-floating">
              <input type="date" className="form-control" {...register("fechaInicio")} />
              <label>2. Periodo de consulta - Fecha de Inicio</label>
            </div>
          </div>
          <div className="col-4">
            <div className="form-floating">
              <input type="date" className="form-control" {...register("fechaFin")} />
              <label>3. Periodo de consulta - Fecha de Fin</label>
            </div>
          </div>
        </form>

        {facturas?.listado?.length > 0 && (
          <ListadoFacturas
            comisionEdificio_1_porcentaje={facturas.comisionEdificio_1_porcentaje}
            comisionEdificio_1_fechainicio={facturas.comisionEdificio_1_fechainicio}
            comisionEdificio_1_fechafin={facturas.comisionEdificio_1_fechafin}
            comisionEdificio_2_porcentaje={facturas.comisionEdificio_2_porcentaje}
            comisionEdificio_2_fechainicio={facturas.comisionEdificio_2_fechainicio}
            comisionEdificio_2_fechafin={facturas.comisionEdificio_2_fechafin}
            comisionEdificio_3_porcentaje={facturas.comisionEdificio_3_porcentaje}
            comisionEdificio_3_fechainicio={facturas.comisionEdificio_3_fechainicio}
            comisionEdificio_3_fechafin={facturas.comisionEdificio_3_fechafin}
            facturas={facturas.listado}
            pantallasEdificio={facturas.pantallasEdificio}
            pais={pais}
          />
        )}
      </Container>
    </>
  );
};
