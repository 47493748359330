/* eslint-disable no-console */
import React, { useCallback, useEffect, useState } from "react";
import { useAuthContext } from "../../context/auth";
import { getOportunidades } from "../../utils";
import "./styles.scss";

export const VentasTabla = () => {
  const [oportunidades, setOportunidades] = useState([]);
  const { pais } = useAuthContext();

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  const moneda = useCallback(
    (pais) =>
      ({
        Chile: "$",
        Perú: "S/",
        Uruguay: "$",
      })[pais] || "$",
    []
  );

  const objetivosPorMes = useCallback((pais) => {
    let objetivos = [];

    if (pais === "Chile") {
      objetivos = [
        5000000, 7500000, 10000000, 15000000, 15000000, 15000000, 15000000, 15000000, 15000000, 25000000, 35000000,
        40000000,
      ];
    } else if (pais === "Perú") {
      objetivos = [90418, 137130, 141569, 128100, 165900, 185900, 185780, 215600, 237000, 221340, 226940, 230300];
    } else if (pais === "Uruguay") {
      objetivos = [600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000, 600000];
    }

    return objetivos;
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const allOportunidades = await getOportunidades({ pais });
        const filteredOportunidades = allOportunidades.filter((oportunidad) => oportunidad.ano === 2024);
        setOportunidades(filteredOportunidades);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [pais]);

  const getMonthName = (index) => {
    const months = ["ENE", "FEB", "MAR", "ABR", "MAY", "JUN", "JUL", "AGO", "SEP", "OCT", "NOV", "DIC"];
    return months[index];
  };

  const getMonth100 = (monthIndex) => {
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) => oportunidad.mes - 1 === monthIndex && oportunidad.estatus === "100%" && !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const getMonth90 = (monthIndex) => {
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) => oportunidad.mes - 1 === monthIndex && oportunidad.estatus === "90%" && !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const getMonth75 = (monthIndex) => {
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) => oportunidad.mes - 1 === monthIndex && oportunidad.estatus === "75%" && !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const getMonth50 = (monthIndex) => {
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) => oportunidad.mes - 1 === monthIndex && oportunidad.estatus === "50%" && !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const renderForecastMensual = (index) => {
    const forecast = getMonth90(index) * 0.75 + getMonth75(index) * 0.5;

    return (
      <td className="text-end">
        {moneda(pais)}
        {forecast.toLocaleString(localString(pais), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </td>
    );
  };

  const getQuarterSubtotal = (quarterIndex) => {
    const startMonth = quarterIndex === 1 ? 1 : quarterIndex === 3 ? 4 : quarterIndex === 6 ? 7 : 10;
    const endMonth = quarterIndex === 1 ? 3 : quarterIndex === 3 ? 6 : quarterIndex === 6 ? 9 : 12;
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) =>
        oportunidad.mes >= startMonth &&
        oportunidad.mes <= endMonth &&
        oportunidad.estatus === "100%" &&
        !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const getHalfSubtotal = (quarterIndex) => {
    const startMonth = quarterIndex === 1 ? 1 : 7;
    const endMonth = quarterIndex === 1 ? 6 : 12;
    const filteredOportunidades = oportunidades.filter(
      (oportunidad) =>
        oportunidad.mes >= startMonth &&
        oportunidad.mes <= endMonth &&
        oportunidad.estatus === "100%" &&
        !oportunidad.eliminada
    );
    const subtotal = filteredOportunidades.reduce((total, oportunidad) => total + oportunidad.importe, 0);
    return subtotal;
  };

  const renderSubtotal = (subtotal) => (
    <td className="text-end">
      {moneda(pais)}
      {subtotal.toLocaleString(localString(pais), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
    </td>
  );

  const renderCumplimiento = (index) => (
    <td className="text-center col-1">
      {((getMonth100(index) / objetivosPorMes(pais)[index]) * 100).toLocaleString(localString(pais), {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      })}
      %
    </td>
  );

  const renderMissing = (index) => {
    const missing = objetivosPorMes(pais)[index] - getMonth100(index);

    return missing < 0 ? (
      <td className="text-end col-1">DONE!</td>
    ) : (
      <td className="text-end col-1">
        {moneda(pais)}
        {missing.toLocaleString(localString(pais), {
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        })}
      </td>
    );
  };

  const totalYTD = objetivosPorMes(pais).reduce((total, monto) => total + monto, 0);

  const totalYTD100 = oportunidades
    .filter((oportunidad) => oportunidad.estatus === "100%" && !oportunidad.eliminada)
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalYTD90 = oportunidades
    .filter((oportunidad) => oportunidad.estatus === "90%" && !oportunidad.eliminada)
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalYTD75 = oportunidades
    .filter((oportunidad) => oportunidad.estatus === "75%" && !oportunidad.eliminada)
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalYTD50 = oportunidades
    .filter((oportunidad) => oportunidad.estatus === "50%" && !oportunidad.eliminada)
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ190 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 3 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ175 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 3 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ150 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes < 3 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ290 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 4 && oportunidad.mes <= 6 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ275 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 4 && oportunidad.mes <= 6 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ250 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 4 && oportunidad.mes <= 6 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ390 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 7 && oportunidad.mes <= 9 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ375 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 7 && oportunidad.mes <= 9 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ350 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 5 && oportunidad.mes <= 9 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ490 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 10 && oportunidad.mes <= 12 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ475 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 10 && oportunidad.mes <= 12 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalQ450 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 10 && oportunidad.mes <= 12 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH190 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH175 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH150 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH290 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "90%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH275 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "75%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const totalH250 = oportunidades
    .filter(
      (oportunidad) =>
        oportunidad.mes >= 1 && oportunidad.mes <= 6 && oportunidad.estatus === "50%" && !oportunidad.eliminada
    )
    .reduce((total, oportunidad) => total + oportunidad.importe, 0);

  const forecastQ1 = totalQ190 * 0.75 + totalQ175 * 0.5;
  const forecastQ2 = totalQ290 * 0.75 + totalQ275 * 0.5;
  const forecastQ3 = totalQ390 * 0.75 + totalQ375 * 0.5;
  const forecastQ4 = totalQ490 * 0.75 + totalQ475 * 0.5;
  const forecastH1 = forecastQ1 + forecastQ2;
  const forecastH2 = forecastQ3 + forecastQ4;

  const totalQ1 = objetivosPorMes(pais)
    .slice(0, 3)
    .reduce((total, monto) => total + monto, 0);
  const totalQ2 = objetivosPorMes(pais)
    .slice(3, 6)
    .reduce((total, monto) => total + monto, 0);
  const totalQ3 = objetivosPorMes(pais)
    .slice(6, 9)
    .reduce((total, monto) => total + monto, 0);
  const totalQ4 = objetivosPorMes(pais)
    .slice(9, 12)
    .reduce((total, monto) => total + monto, 0);

  const totalH1 = totalQ1 + totalQ2;
  const totalH2 = totalQ3 + totalQ4;

  return (
    <div className="d-flex justify-content-center mt-2">
      <table className="table table-striped table-hover table-bordered table-sm" style={{ width: "80%" }}>
        <thead className="table-dark text-center align-middle">
          <tr>
            <th scope="col" style={{ width: "8%" }}>
              MES
            </th>
            <th scope="col" style={{ width: "11.5%" }}>
              OBJETIVO
            </th>
            <th scope="col" style={{ width: "11.5%" }} className="bg-success text-white">
              100%
            </th>
            <th scope="col" style={{ width: "11.5%" }}>
              CUMPLIMIENTO
            </th>
            <th scope="col" style={{ width: "11.5%" }}>
              MISSING
            </th>
            <th scope="col" style={{ width: "11.5%" }} className="bg-danger text-white">
              90%
            </th>
            <th scope="col" style={{ width: "11.5%" }} className="bg-primary text-white">
              75%
            </th>
            <th scope="col" style={{ width: "11.5%" }} className="bg-warning text-white">
              50%
            </th>
            <th scope="col" style={{ width: "11.5%" }} className="bg-info text-white">
              FORECAST
            </th>
          </tr>
        </thead>
        <tbody>
          {[...Array(12)].map((_, index) => (
            <tr key={index}>
              <th scope="row" className="text-center">
                {getMonthName(index)}
              </th>
              <td className="text-end">
                {moneda(pais)}
                {objetivosPorMes(pais)[index].toLocaleString(localString(pais), {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </td>
              {renderSubtotal(getMonth100(index))}
              {renderCumplimiento(index)}
              {renderMissing(index)}
              {renderSubtotal(getMonth90(index))}
              {renderSubtotal(getMonth75(index))}
              {renderSubtotal(getMonth50(index))}
              {renderForecastMensual(index)}
            </tr>
          ))}
          {/* Fila para YTD */}
          <tr className="table-dark">
            <th scope="row" className="text-center">
              YTD
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD100.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-center">
              {((totalYTD100 / totalYTD) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalYTD - totalYTD100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD90.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD75.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalYTD50.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalYTD90 * 0.75 + totalYTD75 * 0.5).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          {/* Filas para trimestres (Q) */}
          <tr>
            <th scope="row" className="text-center">
              Q1
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalQ1.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getQuarterSubtotal(1))}
            <td className="text-center">
              {((getQuarterSubtotal(1) / totalQ1) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalQ1 - getQuarterSubtotal(1)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ190.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ175.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ150.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastQ1.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              Q2
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalQ2.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getQuarterSubtotal(3))}
            <td className="text-center">
              {((getQuarterSubtotal(3) / totalQ2) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalQ2 - getQuarterSubtotal(3)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ290.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ275.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ250.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastQ2.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              Q3
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalQ3.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getQuarterSubtotal(6))}
            <td className="text-center">
              {((getQuarterSubtotal(6) / totalQ3) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalQ3 - getQuarterSubtotal(6)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ390.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ375.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ350.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastQ3.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr>
            <th scope="row" className="text-center">
              Q4
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalQ4.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getQuarterSubtotal(9))}
            <td className="text-center">
              {((getQuarterSubtotal(9) / totalQ4) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalQ4 - getQuarterSubtotal(9)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ490.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ475.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalQ450.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastQ4.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          {/* Filas para semestres (H) */}
          <tr className="table-dark">
            <th scope="row" className="text-center">
              H1
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalH1.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getHalfSubtotal(1))}
            <td className="text-center">
              {((getHalfSubtotal(1) / totalH1) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalH1 - getHalfSubtotal(1)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH190.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH175.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH150.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastH1.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
          <tr className="table-dark">
            <th scope="row" className="text-center">
              H2
            </th>
            <td className="text-end">
              {moneda(pais)}
              {totalH2.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            {renderSubtotal(getHalfSubtotal(2))}
            <td className="text-center">
              {((getHalfSubtotal(5) / totalH2) * 100).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
              %
            </td>
            <td className="text-end">
              {moneda(pais)}
              {(totalH2 - getHalfSubtotal(2)).toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH290.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH275.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {totalH250.toLocaleString(localString(pais))}
            </td>
            <td className="text-end">
              {moneda(pais)}
              {forecastH2.toLocaleString(localString(pais), {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
              })}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
