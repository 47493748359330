import { BlackArrow, Container, VentasTabla } from "../../components";

const VentasProyeccion = () => (
  <div>
    <BlackArrow />
    <Container header titulo="PROYECCIÓN COMERCIAL">
      <VentasTabla />
    </Container>
  </div>
);

export default VentasProyeccion;
