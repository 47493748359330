import toast from "react-hot-toast";
import { log, supabase } from ".";

export const getClientesPublicitarios = async ({ pais }) => {
  try {
    const data = await supabase.select("clientes", {
      filters: { pais },
      orderBy: "nombre",
      ascending: true,
    });

    if (data?.length === 0) {
      toast.error("No hay clientes publicitarios cargados en la base de datos");
      throw new Error("No data found");
    }
    return data;
  } catch (error) {
    log.error("getClientesPublicitarios", error);
    throw error;
  }
};
