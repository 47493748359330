/* eslint-disable no-console */
import { log, supabase } from ".";

export const saveCotizacion = async (cotizacionData) => {
  try {
    // Asegúrate de que los nombres aquí coincidan con los nombres de las columnas en tu base de datos
    const data = await supabase.insert("cotizaciones", {
      ascensores_seleccionados: cotizacionData.ascensoresSeleccionados,
      audiencia_seleccionada_ascensor: cotizacionData.audienciaSeleccionadaAscensor,
      audiencia_seleccionada_total: cotizacionData.audienciaSeleccionadaTotal,
      audiencia_seleccionada_totems: cotizacionData.audienciaSeleccionadaTotems,
      codigo_generado: cotizacionData.codigoGenerado,
      cpm_ascensores_final: cotizacionData.cpmAscensoresFinal,
      cpm_total_final: cotizacionData.cpmTotalFinal,
      cpm_totems_final: cotizacionData.cpmTotemsFinal,
      descuento_total_ascensores: cotizacionData.descuentoTotalAscensores,
      descuento_total_final: cotizacionData.descuentoTotalFinal,
      descuento_total_totems: cotizacionData.descuentoTotalTotems,
      duracion: cotizacionData.duracion,
      edificios_seleccionados_ascensores: cotizacionData.edificiosSeleccionadosAscensores,
      edificios_seleccionados_totems: cotizacionData.edificiosSeleccionadosTotems,
      edificios_unicos_total: cotizacionData.edificiosUnicosTotal,
      horas: cotizacionData.horas,
      horas_final: cotizacionData.horasFinal,
      impactos_total_ascensores: cotizacionData.impactosTotalAscensores,
      impactos_total_totems: cotizacionData.impactosTotalTotems,
      impactos_totales: cotizacionData.impactosTotales,
      intervalos: cotizacionData.intervalos,
      inversion_ascensores_final: cotizacionData.inversionAscensoresFinal,
      inversion_final_total: cotizacionData.inversionFinalTotal,
      inversion_totems_final: cotizacionData.inversionTotemsFinal,
      is_programmatic_selected: cotizacionData.isProgrammaticSelected,
      nombres_edificios_no_seleccionados: cotizacionData.nombresEdificiosNoSeleccionados,
      nombres_edificios_seleccionados_ascensores: cotizacionData.nombresEdificiosSeleccionadosAscensores,
      nombres_edificios_seleccionados_totems: cotizacionData.nombresEdificiosSeleccionadosTotems,
      options: cotizacionData.options,
      pais: cotizacionData.pais,
      plazo: cotizacionData.plazo,
      salidas_ascensores: cotizacionData.salidasAscensores,
      salidas_totales: cotizacionData.salidasTotales,
      salidas_totems: cotizacionData.salidasTotems,
      tarifa_fria_ascensores: cotizacionData.tarifaFriaAscensores,
      tarifa_fria_total: cotizacionData.tarifaFriaTotal,
      tarifa_fria_totems: cotizacionData.tarifaFriaTotems,
      totems_seleccionados: cotizacionData.totemsSeleccionados,
      usuario_apellido: cotizacionData.usuarioApellido,
      usuario_nombre: cotizacionData.usuarioNombre,
    });

    if (!data || data.length === 0) throw new Error("Empty response");

    log.debug("saveCotizacion", data);
    return data;
  } catch (error) {
    log.error("saveCotizacion", error);
    console.error("Error al guardar cotización:", error);
    throw error;
  }
};
