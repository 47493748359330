import React, { useEffect, useState, useCallback, useMemo } from "react";
import { FaCheck } from "react-icons/fa";
import Modal from "react-modal"; // Importa react-modal
import { useAuthContext } from "../../context/auth";
import { getEdificios } from "../../utils";
import { Container } from "../Container";
import FichaEdificioWindow from "../FichaEdificioWindow";
import { Loader } from "../Loader";
import "./styles.scss";

Modal.setAppElement("#root"); // Necesario para accesibilidad

export const InventarioAvanzado = () => {
  const [edificios, setEdificios] = useState();
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortedColumn, setSortedColumn] = useState(null);
  const [selectedEdificioId, setSelectedEdificioId] = useState(null); // Estado para el ID del edificio seleccionado
  const { pais } = useAuthContext();

  useEffect(() => {
    const initialize = async () => {
      const newEdificios = await getEdificios({ pais });

      // Filtra solo los edificios cuyo estado sea 'OPERATIVO' o 'INSTALACIÓN'
      const filteredEdificios = newEdificios.filter(
        (edificio) => edificio.estado === "OPERATIVO" || edificio.estado === "INSTALACIÓN"
      );

      // Luego, ordena los edificios filtrados
      const sortedByEstadoPersonasAndEdificio = [...filteredEdificios].sort((a, b) => {
        if (a.estado < b.estado) return -1;
        if (a.estado > b.estado) return 1;
        if (a.estado === b.estado) {
          return b.personas - a.personas;
        }
        return a.edificio.localeCompare(b.edificio);
      });

      setEdificios(sortedByEstadoPersonasAndEdificio);
    };

    initialize();
  }, [pais]);

  const handleSort = (column) => {
    if (sortedColumn === column) {
      setSortOrder(sortOrder === "asc" ? "desc" : "asc");
    } else {
      setSortedColumn(column);
      setSortOrder("asc");
    }
  };

  const handleEdificioClick = (id) => {
    setSelectedEdificioId(id); // Establece el ID del edificio seleccionado para abrir en la nueva ventana
  };

  const sortedEdificios = useMemo(() => {
    if (!edificios) return [];

    const estadoPrioridad = {
      OPERATIVO: 1,
      INSTALACIÓN: 2,
      STANDBY: 3,
      PRÓXIMO: 4,
    };

    return [...edificios].sort((a, b) => {
      if (sortedColumn === "estado") {
        if (estadoPrioridad[a.estado] < estadoPrioridad[b.estado]) return sortOrder === "asc" ? -1 : 1;
        if (estadoPrioridad[a.estado] > estadoPrioridad[b.estado]) return sortOrder === "asc" ? 1 : -1;
      }

      if (sortedColumn === "personas") {
        return sortOrder === "asc" ? a.personas - b.personas : b.personas - a.personas;
      }

      if (sortedColumn === "edificio") {
        return sortOrder === "asc" ? a.edificio.localeCompare(b.edificio) : b.edificio.localeCompare(a.edificio);
      }

      if (estadoPrioridad[a.estado] < estadoPrioridad[b.estado]) return -1;
      if (estadoPrioridad[a.estado] > estadoPrioridad[b.estado]) return 1;

      if (a.personas !== b.personas) {
        return b.personas - a.personas;
      }

      return a.edificio.localeCompare(b.edificio);
    });
  }, [edificios, sortedColumn, sortOrder]);

  const localString = useCallback(
    (pais) =>
      ({
        Chile: "es-CL",
        Perú: "es-PE",
        Uruguay: "es-UY",
      })[pais] || "es-UY",
    []
  );

  let a = 1;

  return (
    <Container header titulo="INVENTARIO DISPONIBLE">
      <h6 className="texto">(PUEDE REORDENAR EL CONTENIDO SEGÚN SU ENCABEZADO)</h6>
      {sortedEdificios ? (
        <table className="table table-striped table-hover table-bordered mt-4 table-sm">
          <thead className="table-dark text-center align-middle">
            <tr>
              <th width="3%" rowSpan="2">
                #
              </th>
              <th
                width="18%"
                className={`col ${sortedColumn === "edificio" ? "sorted" : ""}`}
                onClick={() => handleSort("edificio")}
                rowSpan="2"
              >
                EDIFICIO
                {sortedColumn === "edificio" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="6%"
                className={`col ${sortedColumn === "estado" ? "sorted" : ""}`}
                onClick={() => handleSort("estado")}
                rowSpan="2"
              >
                STATUS
                {sortedColumn === "estado" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="8%"
                className={`col ${sortedColumn === "ubicacion" ? "sorted" : ""}`}
                onClick={() => handleSort("ubicacion")}
                rowSpan="2"
              >
                UBICACIÓN
                {sortedColumn === "ubicacion" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="20%"
                className={`col ${sortedColumn === "direccion" ? "sorted" : ""}`}
                onClick={() => handleSort("direccion")}
                rowSpan="2"
              >
                DIRECCIÓN
                {sortedColumn === "direccion" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="10%"
                className={`col ${sortedColumn === "programmatic" ? "sorted" : ""}`}
                onClick={() => handleSort("programmatic")}
                rowSpan="2"
              >
                PROGRAMMATIC
                {sortedColumn === "programmatic" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="5%"
                className={`col ${sortedColumn === "personas" ? "sorted" : ""}`}
                onClick={() => handleSort("personas")}
                rowSpan="2"
              >
                AFORO
                {sortedColumn === "personas" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                className={`col ${sortedColumn === "pantallas" ? "sorted" : ""}`}
                onClick={() => handleSort("pantallas")}
                colSpan="4"
              >
                PANTALLAS
                {sortedColumn === "pantallas" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
            </tr>
            <tr className="table-active">
              <th
                width="5%"
                className={`col ${sortedColumn === "ascensores" ? "sorted" : ""}`}
                onClick={() => handleSort("ascensores")}
              >
                ASC.
                {sortedColumn === "ascensores" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="5%"
                className={`col ${sortedColumn === "tótems" ? "sorted" : ""}`}
                onClick={() => handleSort("tótems")}
              >
                CIRC.
                {sortedColumn === "tótems" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="5%"
                scope="col"
                className={`col ${sortedColumn === "espera" ? "sorted" : ""}`}
                onClick={() => handleSort("espera")}
              >
                ESP.
                {sortedColumn === "espera" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
              <th
                width="5%"
                scope="col"
                className={`col ${sortedColumn === "espera" ? "sorted" : ""}`}
                onClick={() => handleSort("espera")}
              >
                TOTAL
                {sortedColumn === "espera" && <span>{sortOrder === "asc" ? " ▲" : " ▼"}</span>}
              </th>
            </tr>
          </thead>

          <tbody className="table-striped text-center align-middle">
            {sortedEdificios.map(
              ({
                id,
                edificio,
                pantallas,
                ubicacion,
                direccion,
                personas,
                estado,
                programatica,
                totems,
                espera,
                cowork,
              }) => {
                const className =
                  estado === "OPERATIVO" ? "estadoOperativo" : estado === "PRÓXIMO" ? "estadoProximo" : "estadoStandby";
                const statusStyle = {
                  backgroundColor:
                    estado === "OPERATIVO"
                      ? "green"
                      : estado === "PRÓXIMO"
                        ? "darkorange"
                        : estado === "STANDBY"
                          ? "red"
                          : "darkorange",
                  color: "white",
                };
                return (
                  <tr key={id}>
                    <td width="5%">{a++}</td>
                    <td width="15%" className="text-start" onClick={() => handleEdificioClick(id)}>
                      {edificio.toUpperCase()}
                    </td>
                    <td width="10%" className={`col ${className}`} style={statusStyle}>
                      {estado}
                    </td>
                    <td width="10%">{ubicacion}</td>
                    <td width="10%" className="text-start">
                      {direccion}
                    </td>
                    <td width="10%">{programatica ? <FaCheck /> : "-"}</td>
                    <td width="8%">
                      {personas.toLocaleString(localString(pais), {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0,
                      })}
                    </td>
                    <td width="5%">{pantallas ? pantallas : "-"}</td>
                    <td width="5%">{totems ? totems : cowork ? cowork : "-"}</td>
                    <td width="5%">{espera ? espera : "-"}</td>
                    <td width="5%">{pantallas + totems + cowork + espera}</td>
                  </tr>
                );
              }
            )}
          </tbody>
          <tfoot className="bottom">
            <tr className="table-danger text-center align-middle">
              <td colSpan="6" />
              <td className="col bottomtotals">
                {sortedEdificios
                  .map((item) => item.personas)
                  .reduce((prev, curr) => prev + curr, 0)
                  .toLocaleString(localString(pais), {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  })}
              </td>
              <td className="col bottomtotals">
                {sortedEdificios.map((item) => item.pantallas).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col bottomtotals">
                {sortedEdificios.map((item) => item.totems + item.cowork).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col bottomtotals">
                {sortedEdificios.map((item) => item.espera).reduce((prev, curr) => prev + curr, 0)}
              </td>
              <td className="col bottomtotals">
                {sortedEdificios
                  .map((item) => item.pantallas + item.totems + item.cowork + item.espera)
                  .reduce((prev, curr) => prev + curr, 0)}
              </td>
            </tr>
          </tfoot>
        </table>
      ) : (
        <Loader />
      )}
      {/* Abrir FichaEdificio en una nueva ventana */}
      {selectedEdificioId && <FichaEdificioWindow id={selectedEdificioId} pais={pais} />}{" "}
      {/* Pasar el país como prop */}
    </Container>
  );
};
