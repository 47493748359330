const cantidadDias = Array.from({ length: 261 }, (_, i) => i + 1);

const modifierByFormat = {
  "FORMATO-A": 1,
  "FORMATO-B": 2,
  "FORMATO-C": 4,
};

export const timeDiscountPercentage = (plazoSelected, formatoSelected) => {
  const descuento = cantidadDias[plazoSelected] * modifierByFormat[formatoSelected] * (0.3 / 261);
  const descuentoMaximo = 0.3;

  return descuento <= descuentoMaximo ? descuento : descuentoMaximo;
};
