import trebuchetRegular from "../../assets/fonts/TREBUC.TTF";
import trebuchetBold from "../../assets/fonts/TREBUCBD.TTF";
import trebuchetBoldItalic from "../../assets/fonts/TREBUCBI.TTF";
import trebuchetItalic from "../../assets/fonts/TREBUCIT.TTF";
import { Font, StyleSheet } from "@react-pdf/renderer";

// Registrar la fuente Trebuchet con las diferentes variantes
Font.register({
  family: "Trebuchet",
  fonts: [
    {
      src: trebuchetRegular, // Regular
    },
    {
      src: trebuchetBold, // Bold
      fontWeight: "bold",
    },
    {
      src: trebuchetItalic, // Italic
      fontStyle: "italic",
    },
    {
      src: trebuchetBoldItalic, // Bold Italic
      fontWeight: "bold",
      fontStyle: "italic",
    },
  ],
});

// Crear los estilos con la fuente Trebuchet
const styles = StyleSheet.create({
  page: {
    padding: 10,
    fontFamily: "Trebuchet", // Usar la familia Trebuchet
    backgroundColor: "#F5F5F5", // Gris claro
  },
  table: {
    display: "table",
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 5,
    width: "95%", // El 100% ahora será el ancho de la página
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    marginTop: 3,
    textAlign: "center",
    color: "red",
  },
  title: {
    fontSize: 18,
    textAlign: "center",
    marginTop: 10,
    marginBottom: 10,
  },
  tableRow: {
    flexDirection: "row",
  },
  tableHeaderTipo: {
    alignItems: "center", // Centra verticalmente
    backgroundColor: "#FF8C00", // Naranja más oscuro
    color: "#ffffff", // Letras blancas
    display: "flex", // Asegura que el contenedor use flexbox
    fontSize: 10,
    height: 20,
    justifyContent: "center", // Centra horizontalmente
    marginRight: "auto",
    marginLeft: "auto",
    marginBottom: 15, // Separación de 10px en la parte inferior
    textAlign: "center", // Asegura que el texto esté centrado
    width: "25%",
    borderRadius: 5, // Esquinas redondeadas
  },
  tableHeader: {
    fontSize: 9,
    backgroundColor: "#000000", // Fondo negro
    color: "#ffffff", // Letras blancas
    textAlign: "center",
  },
  tableHeaderRed: {
    backgroundColor: "#FF0000", // Fondo rojo
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableHeaderGreen: {
    backgroundColor: "#008000", // Fondo verde
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableHeaderBlue: {
    backgroundColor: "#0000FF", // Fondo azul
    color: "#ffffff", // Letras blancas
    fontSize: 9,
    textAlign: "center",
  },
  tableCell: {
    width: "21%",
    textAlign: "center",
    padding: 5,
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellQuote: {
    width: "24%",
    textAlign: "center",
    padding: 5,
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellSmall: {
    width: "18%",
    textAlign: "center",
    padding: 5,
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  tableCellStart: {
    width: "20%",
    textAlign: "start",
    padding: 5,
    borderLeftWidth: 0.2,
    borderRightWidth: 0.2,
    borderColor: "#d3d3d3", // Color gris claro
  },
  lastCell: {
    width: "20%", // Ancho específico para la columna CPM
  },
  tableData: {
    fontSize: 8,
    backgroundColor: "#f8f9fa",
  },
  tableDataTotales: {
    fontSize: 8,
    backgroundColor: "#666666", // Fondo gris intermedio
    color: "white", // Texto blanco para que sea visible sobre fondo negro
  },
  letrachica0: {
    fontSize: 10,
    width: "69vw",
    margin: "auto",
    marginTop: 5,
    marginBottom: 15,
    textAlign: "center",
  },
  letrachica1: {
    fontSize: 10,
    width: "69vw",
    margin: "auto",
    marginTop: 5,
    marginBottom: 15,
    textAlign: "center",
  },
  letrachica2: {
    fontSize: 9,
    width: "80vw",
    margin: "auto",
    marginTop: 5,
    marginBottom: 8,
    textAlign: "center",
  },
  hr: {
    borderBottomColor: "#000", // Color de la línea (puedes cambiarlo a lo que desees)
    borderBottomWidth: 0.5, // Ancho de la línea
    margin: "auto",
    marginVertical: 5, // Espacio arriba y abajo de la línea
    width: "95%", // Ancho de la línea
  },
  horario: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 8,
    width: "90vw",
  },
  horarioTitulo: {
    fontSize: 14,
    marginBottom: 8,
  },
  horariotext: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 10,
  },
  inventario: {
    marginRight: "auto",
    marginLeft: "auto",
    marginTop: 8,
    width: "90vw",
  },
  inventarioTitulo: {
    fontSize: 14,
    marginBottom: 8,
  },
  inventariosubTitulo: {
    fontSize: 12,
    marginBottom: 5,
    marginLeft: 10,
  },
  inventarioNombres: {
    fontSize: 10,
    marginBottom: 5,
    marginLeft: 25,
  },
  inventarioNombresLast: {
    fontSize: 10,
    marginBottom: 5,
    marginLeft: 25,
  },
  image: {
    marginTop: 10,
    marginBottom: 5,
    marginHorizontal: "auto",
    width: 69, // Ancho fijo en unidades PDF
    height: "auto", // Mantiene la proporción de la imagen
  },
  mail: {
    fontSize: 10,
    textAlign: "center",
  },
});

export default styles;
