import React, { useEffect, useRef } from "react";
import ReactDOM from "react-dom/client";
import { FichaEdificioInventario } from "../FichaEdificioInventario"; // Asegúrate de que el path sea correcto
import { Loader } from "../Loader"; // Asegúrate de importar el componente Loader

const FichaEdificioWindow = ({ id, pais }) => {
  const newWindowRef = useRef(null);
  const rootRef = useRef(null); // Almacena la instancia de root para reutilizarla

  useEffect(() => {
    // Abrir una nueva ventana vacía en cada clic
    newWindowRef.current = window.open("", "_blank", "width=1750,height=860,resizable=no");

    if (newWindowRef.current) {
      const newDocument = newWindowRef.current.document;

      // Configurar el documento HTML básico manualmente
      newDocument.write(`
        <!DOCTYPE html>
        <html lang="en">
          <head>
            <title>Ficha Edificio</title>
          </head>
          <body>
            <div id="container"></div>
          </body>
        </html>
      `);
      newDocument.close();

      // Copiar los estilos de la ventana principal a la nueva ventana
      const styles = document.querySelectorAll("link[rel='stylesheet'], style");
      styles.forEach((style) => {
        newDocument.head.appendChild(style.cloneNode(true));
      });

      // Añadir estilos específicos para la nueva ventana
      const backgroundStyle = newDocument.createElement("style");
      backgroundStyle.textContent = `
        body {
          background-color: black;
          color: white;
          margin: 0;
          padding: 0;
          height: 100vh;
          overflow: hidden;
        }
        #container {
          margin-top: 2rem;
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
        }
      `;
      newDocument.head.appendChild(backgroundStyle);

      const container = newDocument.getElementById("container");

      // Crear una nueva raíz para esta ventana
      rootRef.current = ReactDOM.createRoot(container);

      // Renderizar el Loader inicialmente
      rootRef.current.render(<Loader />);

      // Simular carga de datos antes de renderizar el componente
      setTimeout(() => {
        if (newWindowRef.current) {
          // Verificar si la ventana sigue abierta antes de renderizar
          rootRef.current.render(<FichaEdificioInventario id={id} pais={pais} />);
        }
      }, 2000); // Simulación de tiempo de carga de 2 segundos

      // Añadir evento para cerrar la ventana al hacer clic
      newDocument.body.addEventListener("click", () => {
        if (newWindowRef.current) {
          newWindowRef.current.close();
          newWindowRef.current = null; // Resetear referencia de ventana
          rootRef.current = null; // Resetear referencia de root
        }
      });
    }

    // Limpiar cuando el componente se desmonta
    return () => {
      if (newWindowRef.current) {
        newWindowRef.current.close();
        newWindowRef.current = null; // Resetear referencia de ventana
        rootRef.current = null; // Resetear referencia de root
      }
    };
  }, [id, pais]); // Incluir id y pais en las dependencias para recrear la ventana en cada clic

  return null;
};

export default FichaEdificioWindow;
