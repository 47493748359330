import { FaCarSide } from "react-icons/fa";
import { GiSmartphone } from "react-icons/gi";
import { Link } from "react-router-dom";
import { Container } from "../../components";
import "./styles.scss";

export const Categoria = () => (
  <Container className="categoria" header titulo="ANÁLISIS CATEGORÍAS MERCADO">
    <div
      className="botones row align-middle"
      style={{ width: "50%", margin: "0 auto", display: "flex", justifyContent: "center" }}
    >
      <div className="col-6">
        <Link to="/categorias/automotriz" className="link">
          <FaCarSide className="icon" size={56} />
          <span>AUTOMOTRIZ</span>
        </Link>
      </div>

      <div className="col-6">
        <Link to="/categorias/smartphones" className="link">
          <GiSmartphone className="icon" size={56} />
          <span>SMARTPHONES</span>
        </Link>
      </div>
    </div>
  </Container>
);
