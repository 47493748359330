import { BlackArrow, CargaOportunidad } from "../../components";

const CargarOportunidad = () => (
  <div>
    <BlackArrow />
    <CargaOportunidad />
  </div>
);

export default CargarOportunidad;
