import React from "react";
import { BlackArrow, Oportunidades } from "../../components";
import "./styles.scss";

export const Oportunidad = () => (
  <>
    <BlackArrow />
    <Oportunidades />
  </>
);
